import { useState } from "react";
import { Order, OrderItem } from "../../../api/models/orderModel";
import SearchEntry from "../../../components/SearchEntry";
import Tabs from "../../../components/Tab";
import { Button } from "react-bootstrap";
import LabelGroup from "../../../components/LabelGroup";
import React from "react";
import { t } from "i18next";

interface Props {
    show: boolean;
    order: Order;
    onItemClick: (item: OrderItem) => void;
    onFinish: (order: Order) => void;
    onFinishAndPack: (order: Order) => void;
}

const FulfillOrderItemsList = ({
    show,
    order,
    onItemClick,
    onFinish,
    onFinishAndPack,
}: Props) => {
    const [searchText, setSearchText] = useState<string>("");
    const [quantityFilter, setQuantityFilter] = useState<number>(0);

    const getQuantityClassName = (x: OrderItem) =>
        x.quantityFulfilled < x.quantity
            ? "badge bg-danger"
            : x.quantityFulfilled === x.quantity
            ? "badge bg-success"
            : "badge bg-warning text-dark";
    const items = () => {
        let rValue = order.items;

        if (quantityFilter === 1)
            rValue = rValue.filter((x) => x.quantityFulfilled === 0);

        if (quantityFilter === 2)
            rValue = rValue.filter((x) => x.quantity !== x.quantityFulfilled);

        if (!searchText) return rValue;

        const searchUpper = searchText.toLocaleUpperCase();
        return rValue.filter((x) =>
            x.product.name.toLocaleUpperCase().includes(searchUpper)
        );
    };

    if (!show) return <></>;

    const tieneProductosSurtidos = () => {
        const productosSurtidos = order.items.findIndex(
            (x) => x.quantityFulfilled > 0
        );
        return productosSurtidos > -1;
    };

    const iniciarReanudarSurtido = () => {
        let item: OrderItem;
        // Si no ha surtido inicia con el primer producto
        if (!tieneProductosSurtidos()) {
            item = items()[0];
        } else {
            // Si reanuda inicia con el primer producto que no tenga cantidad surtida
            const i = order.items.findIndex((x) => x.quantityFulfilled === 0);
            if (i > -1) item = items()[i];
            else item = items()[0]; // Si no tiene ninguno pendiente inicia con el primero
        }

        onItemClick(item);
    };

    return (
        <div id="FulfillOrderItemsList" className="panel">
            <SearchEntry onChange={(text) => setSearchText(text)} />

            <Tabs
                tabs={[
                    t("fulfillOrderItemsList_filter_all"),
                    t("fulfillOrderItemsList_filter_toBeFulfilled"),
                    t("fulfillOrderItemsList_filter_with_difference"),
                ]}
                selectedTabIndex={quantityFilter}
                onSelectedTabChanged={(selectedTabIndex: number) =>
                    setQuantityFilter(selectedTabIndex)
                }
            />

            <Button variant="success" onClick={() => iniciarReanudarSurtido()}>
                {tieneProductosSurtidos()
                    ? t("fulfillOrderItemsList_resume_fulfillment")
                    : t("fulfillOrderItemsList_start_fulfillment")}
            </Button>

            <div className="mt-2 mb-3">
                {items().map((item, i) => (
                    <div
                        key={i}
                        className="mt-2"
                        onClick={() => onItemClick(item)}
                    >
                        <div className="d-flex">
                            <img
                                style={{ maxWidth: "64px", maxHeight: "64px" }}
                                src={item.product.thumbUrl}
                                alt={item.product.name}
                            />
                            <div className="flex-grow-1 ps-2 d-flex justify-content-between">
                                <span className="flex-grow-1 text-wrap">
                                    <div>{item.product.name}</div>
                                </span>
                            </div>
                            <div className="d-flex">
                                <LabelGroup caption="Solicitado">
                                    <span className="w-100 fs-6 text-center">
                                        {item.quantity}
                                    </span>
                                </LabelGroup>
                                <LabelGroup
                                    className="ms-4"
                                    caption={t(
                                        "fulfillOrderItemsList_requested"
                                    )}
                                >
                                    <span
                                        className={`${getQuantityClassName(
                                            item
                                        )} w-100 fs-6`}
                                    >
                                        {item.quantityFulfilled}
                                    </span>
                                </LabelGroup>
                            </div>
                        </div>
                        {item.notes && (
                            <div className="notes mt-2">{item.notes}</div>
                        )}
                    </div>
                ))}
            </div>

            {tieneProductosSurtidos() && (
                <div className="d-flex justify-content-between">
                    <Button
                        variant="success"
                        size="lg"
                        onClick={() => onFinish(order)}
                    >
                        {t("finish")}
                    </Button>
                    <Button
                        variant="success"
                        size="lg"
                        onClick={() => onFinishAndPack(order)}
                    >
                        {t("finish_and_pack")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FulfillOrderItemsList;
