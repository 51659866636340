import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { eActionType } from "../../api/models/common";
import { t } from "i18next";

interface UpdateKeywordProps {
    actionType: eActionType;
    keyword: string;
    onDelete?: () => void;
    onHide: () => void;
    onSave: (keyword: string) => void;
}

const UpdateKeyword = (props: UpdateKeywordProps) => {
    const [keyword, setKeyword] = useState<string>(props.keyword);
    function save() {}
    return (
        <Modal show={true} backdrop="static" onHide={props.onHide}>
            <Modal.Header closeButton>
                {props.actionType === eActionType.Create
                    ? t("updateKeyword_add")
                    : t("updateKeyword_edit")}
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>{t("updateKeyword_word")}:</Form.Label>
                        <Form.Control
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.currentTarget.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <div className="bottom-toolbar">
                    {props.actionType === eActionType.Update && (
                        <Button
                            size="lg"
                            variant="outline-danger"
                            onClick={props.onDelete}
                        >
                            {t("eliminar")}
                        </Button>
                    )}
                    <Button
                        size="lg"
                        variant="success"
                        onClick={() => props.onSave(keyword)}
                    >
                        {t("save")}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateKeyword;
