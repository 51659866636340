import { Button, FormGroup, FormLabel } from "react-bootstrap";
import { Order, OrderItem } from "../../../api/models/orderModel";
import LabelGroup from "../../../components/LabelGroup";
import NumberSelector from "../../../components/NumberSelector";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import { FulfillItem } from "../../../api/services/ordersServices";
import ErrorModal from "../../../components/ErrorModal";
import { error } from "console";
import { t } from "i18next";

interface Props {
    order: Order;
    item?: OrderItem;
    onItemChanged: (item: OrderItem) => void;
    onNavigateToItem: (item: OrderItem) => void;
    onPause: () => void;
    onFinish: () => void;
}

const FulfillOrderItem = ({
    order,
    item,
    onItemChanged,
    onNavigateToItem,
    onPause,
    onFinish,
}: Props) => {
    const [currentItemIndex, setCurrentItemIndex] = useState<number>(-1);

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    useEffect(() => {
        setCurrentItemIndex(order.items.findIndex((x) => x.id === item?.id));
    }, [item]);

    if (!item) return <></>;

    const navigateToItem = async (newIndex: number) => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await FulfillItem(
                order.id,
                order.items[currentItemIndex].id,
                item.quantityFulfilled
            );

            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }

        if (newIndex > order.items.length - 1) {
            onFinish();
            return;
        }

        setCurrentItemIndex(newIndex);
        onNavigateToItem(order.items[newIndex]);
    };

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <div id="FulfillOrderItem" className="panel">
            <div className="d-flex">
                <img
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                    src={item.product.thumbUrl}
                    alt={item.product.name}
                />
                <div className="flex-grow-1 ps-2 d-flex justify-content-between">
                    <span className="flex-grow-1 lead text-wrap">
                        {item.product.name}
                    </span>
                    <LabelGroup caption={t("fulfillOrderItem_location")}>
                        <strong>{item.product.locationCode}</strong>
                    </LabelGroup>
                </div>
            </div>
            {item.notes && <div className="notes mt-2">{item.notes}</div>}
            <div className="mt-2">
                {t("fulfillOrderItem_requested_quantity")}:
                <span className="lead">
                    {" "}
                    <strong>
                        {item.quantity} {item.product.unitOfMeasure}
                    </strong>
                </span>{" "}
            </div>
            <div className="mt-2">
                <FormGroup>
                    <FormLabel>
                        {t("fulfillOrderItem_fulfilled_quantity")}:
                    </FormLabel>
                    <NumberSelector
                        value={item.quantityFulfilled}
                        onChange={(e) => {
                            if (e > item.quantity) {
                                const diferencia = e / item.quantity;
                                if (diferencia > 1.1) {
                                    if (
                                        !window.confirm(
                                            t(
                                                "fulfillOrderItem_confirm_exceed_quantity"
                                            )
                                        )
                                    )
                                        return;
                                }
                            }
                            item.quantityFulfilled = e;
                            onItemChanged(item);
                        }}
                        numericInputClassName={
                            item.quantityFulfilled < item.quantity
                                ? "quantityBelow"
                                : item.quantityFulfilled === item.quantity
                                ? "quantityEqual"
                                : "quantityAbove"
                        }
                    />
                </FormGroup>
            </div>
            <div className="d-flex mt-5 justify-content-between">
                <Button
                    variant="outline-secondary"
                    disabled={currentItemIndex === 0}
                    onClick={() => navigateToItem(currentItemIndex - 1)}
                >
                    <FontAwesomeIcon icon={faChevronLeft} /> {t("previous")}
                </Button>
                <Button
                    variant="primary"
                    className="flex-grow-1 ms-2"
                    onClick={() => navigateToItem(currentItemIndex + 1)}
                >
                    {t("next")} <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>

            <div className="d-flex flex-column">
                <Button
                    variant="outline-secondary"
                    className="mt-4"
                    onClick={onPause}
                >
                    {t("pause")}
                </Button>
            </div>
        </div>
    );
};

export default FulfillOrderItem;
