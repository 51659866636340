import { NavLink } from "react-router-dom";

interface LogoProps {
    height?: string;
}

const Logo = ({ height = "40px" }: LogoProps) => {
    return (
        <NavLink to=".">
            <img
                src="/img/logo-nube.svg"
                alt="Prontonube logo"
                style={{ height: height, width: "auto" }}
            />
            <img
                className="ms-3"
                style={{ height: height, width: "auto" }}
                src="/img/logo-title.png"
                alt="Prontonube"
            />
        </NavLink>
    );
};

export default Logo;
