import { useState } from "react";
import { RefreshStripeAccountLink } from "../../api/services/tenantServices";
import { GetErrorMessage } from "../../api/helpers/apiHelpers";
import ErrorModal from "../../components/ErrorModal";
import { t } from "i18next";
import { LoadingButton } from "../../components/LoadingButton";

const RefreshStripeAccountLinkPage = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const refreshLink = async () => {
        try {
            setLoading(true);
            const response = await RefreshStripeAccountLink();

            window.location.href = response;
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h2>{t("refreshStripeAccountLinkPage_title")}</h2>
            <h5>{t("refreshStripeAccountLinkPage_link_expired")}</h5>
            <p className="mt-4">
                {t("refreshStripeAccountLinkPage_instructions")}
            </p>
            <LoadingButton
                className="btn btn-primary mt-4 w-100"
                onClick={() => refreshLink()}
                isLoading={loading}
            >
                {t("refreshStripeAccountLinkPage_create_new_link")}
            </LoadingButton>

            {errorMessage && (
                <ErrorModal
                    errorMessage={errorMessage}
                    onOkClick={() => setErrorMessage("")}
                />
            )}
        </div>
    );
};

export default RefreshStripeAccountLinkPage;
