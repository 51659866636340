import { t } from "i18next";

const StripeOnboardingCompleted = () => {
    return (
        <div>
            <h2>{t("stripeOnboardingCompleted_title")}</h2>
            <p className="mt-4">
                {t("stripeOnboardingCompleted_instructions")}
            </p>
        </div>
    );
};

export default StripeOnboardingCompleted;
