import { Button, FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import NumericInput from "../../components/NumericInput";
import { useState } from "react";
import LocalDeliveryZones from "./LocalDelivery/LocalDeliveryZones";

import { t } from "i18next";

interface Props {
    pickupEnabled: boolean;
    pickupMinimumAmount: number;
    pickupDeliveryTime: number;
    pickupDeliveryFee: number;
    deliveryEnabled: boolean;
    deliveryMinimumAmount: number;
    deliveryFreeShippingMinimumAmount: number;
    localDeliveryTime: number;
    localDeliveryFee: number;
    deliveryTime: number;
    deliveryFee: number;
    onPropertyChanged: (propertyName: string, value: any) => void;
}
const StoreSettingsDeliveryMethods = ({
    pickupEnabled,
    pickupMinimumAmount,
    pickupDeliveryTime,
    pickupDeliveryFee,
    deliveryEnabled,
    deliveryMinimumAmount,
    deliveryFreeShippingMinimumAmount,
    localDeliveryFee,
    localDeliveryTime,
    deliveryTime,
    deliveryFee,
    onPropertyChanged,
}: Props) => {
    const [localDeliveryZonesVisible, setLocalDeliveryZonesVisible] =
        useState<boolean>(false);
    return (
        <div>
            <div id="pickup" className="panel">
                <FormLabel>
                    {t("storeSettingsDeliveryMethods_pickup")}
                </FormLabel>
                <FormCheck
                    label={t("storeSettingsDeliveryMethods_pickup_check")}
                    checked={pickupEnabled}
                    onChange={() =>
                        onPropertyChanged("pickupEnabled", !pickupEnabled)
                    }
                />
                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_min_amount")}
                    </FormLabel>
                    <NumericInput
                        disabled={!pickupEnabled}
                        value={pickupMinimumAmount}
                        onValueChanged={(e) =>
                            onPropertyChanged("pickupMinimumAmount", e)
                        }
                        placeholder={t(
                            "storeSettingsDeliveryMethods_min_amount_pickup_placeholder"
                        )}
                    />
                </FormGroup>
                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_delivery_time")}
                    </FormLabel>
                    <NumericInput
                        disabled={!pickupEnabled}
                        placeholder={t(
                            "storeSettingsDeliveryMethods_delivery_time_placeholder"
                        )}
                        value={pickupDeliveryTime}
                        onValueChanged={(e) =>
                            onPropertyChanged("pickupDeliveryTime", e)
                        }
                    />
                </FormGroup>
                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_pickup_fee")}
                    </FormLabel>
                    <NumericInput
                        disabled={!pickupEnabled}
                        placeholder={t(
                            "storeSettingsDeliveryMethods_pickup_fee_placeholder"
                        )}
                        value={pickupDeliveryFee}
                        onValueChanged={(e) =>
                            onPropertyChanged("pickupDeliveryFee", e)
                        }
                    />
                </FormGroup>
            </div>

            <div id="delivery" className="panel">
                <FormLabel>
                    {t("storeSettingsDeliveryMethods_delivery")}
                </FormLabel>
                <FormCheck
                    label={t("storeSettingsDeliveryMethods_delivery_check")}
                    checked={deliveryEnabled}
                    onChange={() =>
                        onPropertyChanged("deliveryEnabled", !deliveryEnabled)
                    }
                />
                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_min_amount")}
                    </FormLabel>
                    <NumericInput
                        disabled={!deliveryEnabled}
                        value={deliveryMinimumAmount}
                        onValueChanged={(e) =>
                            onPropertyChanged("deliveryMinimumAmount", e)
                        }
                        placeholder={t(
                            "storeSettingsDeliveryMethods_min_amount_delivery_placeholder"
                        )}
                    />
                </FormGroup>

                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_free_delivery")}
                    </FormLabel>
                    <NumericInput
                        disabled={!deliveryEnabled}
                        value={deliveryFreeShippingMinimumAmount}
                        onValueChanged={(e) =>
                            onPropertyChanged(
                                "deliveryFreeShippingMinimumAmount",
                                e
                            )
                        }
                        placeholder={t(
                            "storeSettingsDeliveryMethods_free_delivery_placeholder"
                        )}
                    />
                </FormGroup>

                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_local_delivery_time")}
                    </FormLabel>
                    <NumericInput
                        disabled={!deliveryEnabled}
                        placeholder={t(
                            "storeSettingsDeliveryMethods_local_delivery_time_placeholder"
                        )}
                        value={localDeliveryTime}
                        onValueChanged={(e) =>
                            onPropertyChanged("localDeliveryTime", e)
                        }
                    />
                </FormGroup>
                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_local_delivery_fee")}
                    </FormLabel>
                    <NumericInput
                        disabled={!deliveryEnabled}
                        placeholder={t(
                            "storeSettingsDeliveryMethods_local_delivery_fee"
                        )}
                        value={localDeliveryFee}
                        onValueChanged={(e) =>
                            onPropertyChanged("localDeliveryFee", e)
                        }
                    />
                </FormGroup>

                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_delivery_time")}
                    </FormLabel>
                    <NumericInput
                        disabled={!deliveryEnabled}
                        placeholder={t(
                            "storeSettingsDeliveryMethods_delivery_time_placeholder"
                        )}
                        value={deliveryTime}
                        onValueChanged={(e) =>
                            onPropertyChanged("deliveryTime", e)
                        }
                    />
                </FormGroup>
                <FormGroup className="formGroup">
                    <FormLabel>
                        {t("storeSettingsDeliveryMethods_delivery_fee")}
                    </FormLabel>
                    <NumericInput
                        disabled={!deliveryEnabled}
                        placeholder={t(
                            "storeSettingsDeliveryMethods_delivery_fee"
                        )}
                        value={deliveryFee}
                        onValueChanged={(e) =>
                            onPropertyChanged("deliveryFee", e)
                        }
                    />
                </FormGroup>

                <Button
                    className="mt-4"
                    onClick={() => setLocalDeliveryZonesVisible(true)}
                >
                    {t("storeSettingsDeliveryMethods_local_delivery_zones")}
                </Button>

                <LocalDeliveryZones
                    show={localDeliveryZonesVisible}
                    onClose={() => setLocalDeliveryZonesVisible(false)}
                />
            </div>
        </div>
    );
};

export default StoreSettingsDeliveryMethods;
