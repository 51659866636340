import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, FormSelect } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Product } from "../../api/models/productModel";
import {
    BottomLevelCategory,
    GetBottomLevelCategories,
} from "../../api/services/productCategoriesServices";
import { GetProducts } from "../../api/services/productServices";
import SearchEntry from "../../components/SearchEntry";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";
import { useSession } from "../../api/context/SessionContext";
import { t } from "i18next";

const ProductsListPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [products, setProducts] = useState<Product[]>([]);
    const [categories, setCategories] = useState<BottomLevelCategory[]>([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);

    const [searchText, setSearchText] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("7fc266e4-745b-4da2-b8ba-3c90bdc15e0b")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
        }

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingError(undefined);

            const categories = await GetBottomLevelCategories();
            setCategories(categories);

            const result = await GetProducts();
            setProducts(result);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")} <br /> {t("please_try_again")}.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const getProducts = () => {
        let ds = products;

        console.log("categories", categories);
        console.log("selectedCategoryID", selectedCategoryId);

        const selectedCategory = categories.find(
            (x) => x.id === selectedCategoryId
        );
        console.log("selectedCategory", selectedCategory);

        if (selectedCategory)
            ds = ds.filter(
                (x) =>
                    x.productCategoryId === selectedCategory.categoryId &&
                    (!x.productSubCategoryId ||
                        x.productSubCategoryId ===
                            selectedCategory.subCategoryId)
            );

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter((x) =>
                x.name.toLocaleLowerCase().includes(searchTextLower)
            );
        }

        return ds.sort((a, b) =>
            a.order < b.order ? -1 : a.order > b.order ? 1 : 0
        );
    };

    if (isLoading) return <LoadingComponent />;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => setLoadingError(undefined)}
            />
        );

    return (
        <div>
            <h1>{t("productsListPage_title")}</h1>

            <SearchEntry onChange={(e) => setSearchText(e)} />

            <div id="toolbar" className="toolbar">
                <FormSelect
                    disabled={categories.length === 0}
                    onChange={(e) =>
                        setSelectedCategoryId(parseInt(e.currentTarget.value))
                    }
                >
                    <option value={0}>
                        {t("productsListPage_all_categories")}
                    </option>
                    {categories?.map((x, i) => (
                        <option key={x.id} value={x.id}>
                            {x.name}
                        </option>
                    ))}
                </FormSelect>
                <Button
                    id="addCategory"
                    variant="success"
                    onClick={() => navigate("../products-add")}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>

            <div id="productsList">
                {getProducts().map((x, i) => (
                    <div
                        className="panel"
                        key={i}
                        onClick={() => navigate(`../products/${x.id}`)}
                    >
                        <div className="row">
                            <div className="col-2">
                                <img
                                    style={{
                                        maxWidth: "48px",
                                        maxHeight: "48px",
                                    }}
                                    src={x.thumbUrl}
                                    alt={x.name}
                                />
                            </div>
                            <div className="col d-flex align-items-center">
                                {x.name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsListPage;
