import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ePaymentType } from "../../api/models/orderModel";
import { faCreditCard, faMoneyBill } from "@fortawesome/free-solid-svg-icons";

interface Props {
  paymentMethod: ePaymentType;
  className?: string;
}
const PaymentMethodBadge = ({ paymentMethod, className }: Props) => {
  return (
    <FontAwesomeIcon
      className={className}
      style={{ fontSize: "1.8rem" }}
      icon={paymentMethod === ePaymentType.Card ? faCreditCard : faMoneyBill}
    />
  );
};

export default PaymentMethodBadge;
