import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormatDecimal, FormatDate } from "../../../api/helpers/formatters";
import {
    eDeliveryType,
    eOrderStatus,
    Order,
    OrderItem,
} from "../../../api/models/orderModel";
import {
    AddOrderItem,
    CalculateOrder,
    CancelOrder,
    DeleteOrderItem,
    GetOrder,
    ResendOrderEmail,
    UpdateOrder,
    UpdateOrderItem,
} from "../../../api/services/ordersServices";
import { UserHasPermission } from "../../../api/services/UsersServices";
import LabelGroup from "../../../components/LabelGroup";
import DeliveryTypeBadge from "../Components/DeliveryTypeBadge";
import EditOrder from "./EditOrder";
import OrderStatusBadge from "../Components/OrderStatusBadge";
import EditOrderItem from "./EditOrderItem";
import AddressBox from "../../../components/AddressBox";
import PaymentMethodBadge from "../../PaymentMethods/PaymentMethodBadge";
import TitleBar from "../../../components/TitleBar";
import React from "react";
import { set } from "date-fns";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import ErrorModal from "../../../components/ErrorModal";
import LoadingComponent from "../../../components/LoadingComponent";
import { ca } from "date-fns/locale";
import { useSession } from "../../../api/context/SessionContext";
import { t } from "i18next";

const ViewOrder = () => {
    const params = useParams();
    const navigate = useNavigate();
    const session = useSession();

    const [order, setOrder] = useState<Order | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const [allowEditOrder, setAllowEditOrder] = useState(false);
    const [showEditOrder, setShowEditOrder] = useState(false);

    const [allowCancelOrder, setAllowCancelOrder] = useState(false);

    const [allowEditItems, setAllowEditItems] = useState(false);
    const [editingItem, setEditingItem] = useState<OrderItem | undefined>();
    const [showEditItem, setShowEditItem] = useState<boolean>(false);

    useEffect(() => {
        setAllowEditOrder(UserHasPermission("Orders/EditOrder"));
        setAllowCancelOrder(UserHasPermission("Orders/CancelOrder"));
        setAllowEditItems(UserHasPermission("Orders/EditItems"));

        fetchData();
    }, []);

    const fetchData = async () => {
        if (!params.orderId) {
            setErrorMessage(t("viewOrder_parameters_error"));
            return;
        }

        try {
            setIsLoading(true);
            setErrorMessage(undefined);
            const response = await GetOrder(params.orderId);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            // Si el pedido está entregado o cancelado, no se puede editar
            if (
                response.order.status === eOrderStatus.Delivered ||
                response.order.status === eOrderStatus.Cancelled
            ) {
                setAllowEditOrder(false);
                setAllowCancelOrder(false);
                setAllowEditItems(false);
            }

            setOrder(response.order);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleResend = async () => {
        if (!window.confirm(t("viewOrder_confirm_resend"))) return;

        try {
            setIsLoading(true);
            setErrorMessage(undefined);
            const response = await ResendOrderEmail(String(order?.id!));
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancelar = async () => {
        if (!window.confirm(t("viewOrder_confirm_cancel"))) return;

        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await CancelOrder(
                order!.id,
                session.getLoggedUser()!.userId
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            navigate("../orders");
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={!order ? fetchData : undefined}
                onOkClick={() => {
                    if (!order) navigate(-1);
                    else setErrorMessage(undefined);
                }}
            />
        );

    if (isLoading || !order) return <LoadingComponent />;

    const handleUpdateOrder = async (order: Order) => {
        try {
            setErrorMessage(undefined);

            const response = await UpdateOrder(order);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrder(order);
            setShowEditOrder(false);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    if (showEditOrder)
        return (
            <EditOrder
                order={order}
                onHide={() => setShowEditOrder(false)}
                onComplete={handleUpdateOrder}
            />
        );

    const handleUpdateOrderItem = async (item: OrderItem) => {
        if (!order) return;

        // Si está agregando una nueva partida
        if (item.id === 0) {
            // Valida que no agregue un producto que ya existe
            const i = order.items.findIndex(
                (i) => i.product.id === item.product.id && i.id !== item.id
            );
            if (i !== -1) {
                alert(t("viewOrder_product_already_added"));
                return;
            }

            try {
                setErrorMessage(undefined);

                const response = await AddOrderItem(order, item);
                if (!response.success) {
                    setErrorMessage(response.message);
                    return;
                }
                item.price = response.price;
                item.amount = response.amount;

                // Agrega la partida
                order.items.push(item);

                // Por alguna razon no esta actualizando el estado del cambio en la orden
                window.location.reload();
            } catch (error) {
                setErrorMessage(GetErrorMessage(error));
                await fetchData();
                return;
            } finally {
                setIsLoading(false);
            }
        } else {
            try {
                setErrorMessage(undefined);

                const response = await UpdateOrderItem(order, item);
                if (!response.success) {
                    setErrorMessage(response.message);
                    return;
                }

                item.price = response.price;
                item.amount = response.amount;
                order.discount = response.couponAmount;

                // Modifica la partida
                const index = order.items.findIndex((i) => i.id === item.id);
                if (index === -1) {
                    return;
                }

                const items = order.items.map((i) => {
                    if (i.id === item.id) return item;
                    return i;
                });

                setOrder({ ...order, items: items });
                // Por alguna razon no esta actualizando el estado del cambio en la orden
                window.location.reload();
                return;
            } catch (error) {
                setErrorMessage(GetErrorMessage(error));
                return;
            } finally {
                setIsLoading(false);
            }
        }

        // Recalcula los totales
        CalculateOrder(order);

        setOrder({ ...order });
        setEditingItem(undefined);
        setShowEditItem(false);
    };

    const handleDeleteItem = async (item: OrderItem) => {
        if (!order) return;

        if (!window.confirm(t("viewOrder_confirm_delete_item"))) return;

        try {
            setErrorMessage(undefined);

            const response = await DeleteOrderItem(order, item);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
            const filteredItems = order.items.filter((i) => i.id !== item.id);
            order.items = filteredItems;

            CalculateOrder(order);

            setOrder({ ...order });
            setEditingItem(undefined);
            setShowEditItem(false);

            // Por alguna razon no esta actualizando el estado del cambio en la orden
            window.location.reload();
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setIsLoading(false);
        }
    };

    if (showEditItem)
        return (
            <EditOrderItem
                item={editingItem}
                onHide={() => {
                    setEditingItem(undefined);
                    setShowEditItem(false);
                }}
                onComplete={handleUpdateOrderItem}
                onDelete={handleDeleteItem}
            />
        );

    return (
        <div id="viewOrder">
            <div className="d-flex justify-content-between">
                <TitleBar
                    title={`${t("order")} ${order.id}`}
                    backUrl="../orders"
                >
                    <OrderStatusBadge orderStatus={order.status} />
                </TitleBar>
            </div>

            <div id="order" className="panel">
                <div className="d-flex justify-content-between">
                    <LabelGroup caption={t("order_date")}>
                        {FormatDate(order.dateEntered, true)}
                    </LabelGroup>
                    <LabelGroup caption={t("promised_delivery_date")}>
                        {order.promisedDeliveryDate}
                    </LabelGroup>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    <div>
                        <p>{order.customer.name}</p>
                        <p>
                            <small>{order.customer.email}</small>
                        </p>
                        <p>
                            <small>{order.customer.phone} </small>
                            <a href={`tel:${order.customer.phone}`}>
                                <span className="ms-2 py-2 px-3 badge rounded-pill bg-success">
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                    ></FontAwesomeIcon>
                                </span>
                            </a>
                        </p>
                    </div>

                    <PaymentMethodBadge
                        className="ms-2"
                        paymentMethod={order.paymentType}
                    />
                </div>
                <DeliveryTypeBadge
                    className="my-1"
                    deliveryType={order.deliveryType}
                />
                {order.deliveryType === eDeliveryType.Delivery &&
                    order.shippingAddress && (
                        <AddressBox address={order.shippingAddress} />
                    )}

                {order.notes && (
                    <div className="mt-2">
                        <div>{t("notes")}:</div>
                        <div className="notes">{order.notes}</div>
                    </div>
                )}

                <div className="mt-2">
                    {allowEditOrder && (
                        <Button
                            variant="outline-secondary"
                            onClick={() => setShowEditOrder(true)}
                        >
                            {t("edit")}
                        </Button>
                    )}
                </div>
            </div>

            <div id="orderItems" className="panel">
                {order.items.map((x, i) => (
                    <div
                        key={i}
                        className="d-flex"
                        onClick={() => {
                            if (!allowEditItems) {
                                alert(
                                    t("viewOrder_no_permission_edit_product")
                                );
                                return;
                            }
                            setEditingItem(x);
                            setShowEditItem(true);
                        }}
                    >
                        <div>
                            <img
                                style={{ maxWidth: "64px", maxHeight: "64px" }}
                                src={x.product.thumbUrl}
                                alt={x.product.name}
                            />
                        </div>
                        <div className="flex-grow-1 ps-2">
                            <div>{x.product.name}</div>
                            <div className="smallText d-flex justify-content-between">
                                <div>
                                    <strong>{x.quantity}</strong>{" "}
                                    {x.product.unitOfMeasure}
                                </div>
                                <div>
                                    <strong>
                                        {FormatDecimal(x.amount, true)}
                                    </strong>
                                </div>
                            </div>
                            {x.notes && (
                                <div className="notes mt-1">{x.notes}</div>
                            )}
                            <hr />
                        </div>
                    </div>
                ))}

                {allowEditItems && (
                    <Button
                        variant="outline-success mb-2"
                        onClick={() => {
                            setEditingItem(undefined);
                            setShowEditItem(true);
                        }}
                    >
                        {t("viewOrder_add_item")}
                    </Button>
                )}
                <div>
                    <div className="row">
                        <div className="col-8 text-end">Subtotal</div>
                        <div className="col-4 text-end">
                            <strong>
                                {FormatDecimal(order.subTotal, true)}
                            </strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 text-end">- {t("coupon")}</div>
                        <div className="col-4 text-end">
                            <span className="text-danger">
                                {FormatDecimal(order.discount, false)}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 text-end">
                            + {t("delivery_fee")}
                        </div>
                        <div className="col-4 text-end">
                            {FormatDecimal(order.deliveryFee, false)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 text-end">Total</div>
                        <div className="col-4 text-end">
                            <strong>{FormatDecimal(order.total, true)}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <div className="my-4 flex d-flex justify-content-between">
                {allowCancelOrder && (
                    <Button
                        variant="outline-danger"
                        className="me-2"
                        onClick={handleCancelar}
                    >
                        {t("cancel")}
                    </Button>
                )}

                {(allowEditOrder || allowEditItems) && (
                    <Button
                        variant="primary"
                        className="flex-grow-1"
                        onClick={handleResend}
                    >
                        {t("viewOrder_resend_mail")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ViewOrder;
