import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Navbar } from "react-bootstrap";
import React from "react";
import Logo from "../Logo";
import SideBar from "./sidebar";
const NavBar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Test

  return (
    <Navbar bg="light" sticky="top">
      <Button
        variant="outline"
        onClick={() => setSidebarVisible(!sidebarVisible)}
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
      <Logo />
      <SideBar
        visible={sidebarVisible}
        onCloseButton={() => setSidebarVisible(false)}
      />
    </Navbar>
  );
};

export default NavBar;
