import { Order } from "./orderModel";

export enum eCouponStatus {
    None = -1,
    Active = 0,
    Applied,
    Canceled,
    Expired,
}

export type Coupon = {
    id: number;
    code: string;
    amount: number;
    isPercentaje: boolean;
    minimumPurchaseAmount: number;
    dueDate: Date;
    status: eCouponStatus;
    description: string;
    issuedBy: string;
    dateApplied?: Date;
    orderIdApplied?: number;
    order?: Order;
};

export type CreateCouponRequest = {
    numberOfCoupons: number;
    description: string;
    isPercentaje: boolean;
    amount: number;
    minimumPurchaseAmount: number;
    dueDate: Date;
    issuedBy: string;
};
