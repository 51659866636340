import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Store } from "../models/storeModel";
import { GetStore } from "../services/storeServices";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";

const StoreContext = createContext({} as tStoreContext);

type tStoreContext = {
  store: Store;
};

type StoreContextProviderProps = {
  children: ReactNode;
};

export function StoreContextProvider({ children }: StoreContextProviderProps) {
  const [store, setStore] = useState<Store>();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<React.ReactNode>();

  useEffect(() => {
    if (!store) fetchData();
  }, [store]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setLoadingError(undefined);

      const store = await GetStore();

      setStore(store);
    } catch (error) {
      let message = error;
      if (error instanceof Error) message = error.message;
      setLoadingError(
        <p>
          Ha ocurrido un error al cargar la tienda. <br /> Verifique la
          dirección y reintente más tarde.
        </p>
      );
      console.log(message, error);
    } finally {
      setIsLoading(false);
    }
  };

  if (loadingError)
    return <ErrorModal errorMessage={loadingError} onRetryClick={fetchData} />;

  if (isLoading || !store) return <LoadingComponent />;

  return (
    <StoreContext.Provider value={{ store }}>{children}</StoreContext.Provider>
  );
}

export function useStoreContext() {
  return useContext(StoreContext);
}
