import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
    props
) => {
    const { i18n } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem("i18nextLng", language);
        if (language === "es") localStorage.setItem("i18culture", "es-MX");
        if (language === "en") localStorage.setItem("i18culture", "en-US");
        setDropdownOpen(false);
    };

    const currentLanguage = i18n.language;

    // Styles
    const dropdownMenuUpStyle: React.CSSProperties = {
        top: "auto",
        bottom: "100%",
        marginTop: 0,
        marginBottom: "0.125rem",
        right: 0,
        left: "auto",
    };

    return (
        <div {...props} className={`dropdown ${props.className || ""}`}>
            <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="languageDropdown"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
                onClick={toggleDropdown}
            >
                {currentLanguage === "en" ? "English" : "Español"}
            </button>
            <div
                className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}
                aria-labelledby="languageDropdown"
                style={dropdownOpen ? dropdownMenuUpStyle : undefined}
            >
                <button
                    className="dropdown-item"
                    onClick={() => changeLanguage("en")}
                >
                    English
                </button>
                <button
                    className="dropdown-item"
                    onClick={() => changeLanguage("es")}
                >
                    Español
                </button>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
