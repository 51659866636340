import { t } from "i18next";
import { eOrderStatus, eOrderStatusName } from "../../../api/models/orderModel";

interface OrderStatusBadgeProps {
    orderStatus: eOrderStatus;
}
const OrderStatusBadge = ({ orderStatus }: OrderStatusBadgeProps) => {
    const badgeColors = () => {
        switch (orderStatus) {
            case eOrderStatus.Pending:
                return "bg-light text-dark";
            case eOrderStatus.Picking:
                return "bg-primary";
            case eOrderStatus.Picked:
                return "bg-warning text-dark";
            case eOrderStatus.Packaged:
                return "bg-info text-dark";
            case eOrderStatus.Shipped:
                return "bg-secondary";
            case eOrderStatus.Delivered:
                return "bg-success";
            case eOrderStatus.Cancelled:
                return "bg-danger";
            default:
                return "bg-light text-dark";
        }
    };

    return (
        <span
            className={`badge rounded-pill ${badgeColors()}`}
            style={{ height: "1.4rem", alignSelf: "center" }}
        >
            {t(eOrderStatusName[orderStatus])}
        </span>
    );
};

export default OrderStatusBadge;
