import { useEffect, useState } from "react";
import { Alert, Form, Toast } from "react-bootstrap";
import { useStoreContext } from "../../api/context/StoreContext";
import { Store } from "../../api/models/storeModel";
import { IError } from "../../api/models/errorModel";
import StoreSettingsNameLogo from "./StoreSettingsNameLogo";
import StoreSettingsCarousel from "./StoreSettingsCarousel";
import StoreSettingsContact from "./StoreSettingsContact";
import StoreSettingsDeliveryMethods from "./StoreSettingsDeliveryMethods";
import Tabs from "../../components/Tab";
import StoreSettingsWorkingDays from "./StoreSettingsWorkingDays";
import { UpdateStore } from "../../api/services/storeServices";
import TitleBar from "../../components/TitleBar";
import { LoadingButton } from "../../components/LoadingButton";
import { useSession } from "../../api/context/SessionContext";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const StoreSettings = () => {
    const navigate = useNavigate();
    const session = useSession();

    const { store: storeContext } = useStoreContext();
    const [store, setStore] = useState<Store>(storeContext);

    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

    const [showSaved, setShowSaved] = useState<boolean>(false);

    const [error, setError] = useState<IError>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("42414780-2494-4921-af0b-83c666237659")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
            return;
        }
    }, []);

    async function save() {
        if (!validate()) return;

        try {
            setError(undefined);
            setIsLoading(true);

            if (
                store.contactInfo.homePageUrl &&
                !store.contactInfo.homePageUrl.startsWith("https")
            ) {
                store.contactInfo.homePageUrl =
                    "https://" + store.contactInfo.homePageUrl;
            }

            const response = await UpdateStore(store);
            if (!response.success) {
                if (response.message) setError({ message: response.message });
                else
                    setError({
                        message: t("storeSettings_error_saving"),
                    });
                return;
            }

            Object.assign(storeContext, store);

            setShowSaved(true);
        } catch (error) {
            console.log(error);
            setError({ message: t("storeSettings_error_saving") });
            return;
        } finally {
            setIsLoading(false);
        }
    }

    function validate() {
        if (!store.name) {
            setError({
                message: t("storeSettings_name_required"),
                property: "storeName",
            });
            return false;
        }

        setError(undefined);
        return true;
    }

    return (
        <div>
            <TitleBar title={t("storeSettings_title")} />
            <Form>
                <Tabs
                    tabs={[
                        t("storeSettings_tabs_store"),
                        t("storeSettings_tabs_carousel"),
                        t("storeSettings_tabs_delivery"),
                        t("storeSettings_tabs_contact"),
                    ]}
                    selectedTabIndex={selectedTabIndex}
                    onSelectedTabChanged={(tabIndex) =>
                        setSelectedTabIndex(tabIndex)
                    }
                />

                {selectedTabIndex === 0 && (
                    <div>
                        <StoreSettingsNameLogo
                            storeName={store.name}
                            logoUrl={store.logoUrl}
                            error={error}
                            onPropertyChanged={(property, value) => {
                                switch (property) {
                                    case "storeName":
                                        setStore({ ...store, name: value });
                                        break;
                                    case "logoUrl":
                                        setStore({ ...store, logoUrl: value });
                                        break;
                                }
                            }}
                        />

                        <StoreSettingsWorkingDays
                            workingDays={store.workingDays}
                            onChange={(value: number[]) => {
                                setStore({ ...store, workingDays: value });
                            }}
                        />
                    </div>
                )}

                {selectedTabIndex === 1 && (
                    <StoreSettingsCarousel
                        carouselUrls={store.carouselUrls}
                        onPropertyChanged={(property, value) => {
                            switch (property) {
                                case "carouselUrls":
                                    setStore({ ...store, carouselUrls: value });
                                    break;
                                case "refresh":
                                    setRefresh(!refresh);
                                    break;
                            }
                        }}
                    />
                )}

                {selectedTabIndex === 2 && (
                    <StoreSettingsDeliveryMethods
                        pickupEnabled={store.pickupEnabled}
                        pickupMinimumAmount={store.pickupMinimumAmount}
                        deliveryEnabled={store.deliveryEnabled}
                        deliveryMinimumAmount={store.deliveryMinimumAmount}
                        deliveryFreeShippingMinimumAmount={
                            store.deliveryFreeShippingMinimumAmount
                        }
                        pickupDeliveryTime={store.pickupDeliveryTime}
                        pickupDeliveryFee={store.pickupDeliveryFee}
                        localDeliveryTime={store.localDeliveryTime}
                        localDeliveryFee={store.localDeliveryFee}
                        deliveryTime={store.deliveryTime}
                        deliveryFee={store.deliveryFee}
                        onPropertyChanged={(property, value) => {
                            switch (property) {
                                case "pickupEnabled":
                                    setStore({
                                        ...store,
                                        pickupEnabled: value,
                                    });
                                    break;
                                case "pickupMinimumAmount":
                                    setStore({
                                        ...store,
                                        pickupMinimumAmount: value,
                                    });
                                    break;
                                case "deliveryEnabled":
                                    setStore({
                                        ...store,
                                        deliveryEnabled: value,
                                    });
                                    break;
                                case "deliveryMinimumAmount":
                                    setStore({
                                        ...store,
                                        deliveryMinimumAmount: value,
                                    });
                                    break;
                                case "deliveryFreeShippingMinimumAmount":
                                    setStore({
                                        ...store,
                                        deliveryFreeShippingMinimumAmount:
                                            value,
                                    });
                                    break;
                                case "pickupDeliveryTime":
                                    setStore({
                                        ...store,
                                        pickupDeliveryTime: value,
                                    });
                                    break;
                                case "pickupDeliveryFee":
                                    setStore({
                                        ...store,
                                        pickupDeliveryFee: value,
                                    });
                                    break;
                                case "localDeliveryTime":
                                    setStore({
                                        ...store,
                                        localDeliveryTime: value,
                                    });
                                    break;
                                case "localDeliveryFee":
                                    setStore({
                                        ...store,
                                        localDeliveryFee: value,
                                    });
                                    break;
                                case "deliveryTime":
                                    setStore({
                                        ...store,
                                        deliveryTime: value,
                                    });
                                    break;
                                case "deliveryFee":
                                    setStore({
                                        ...store,
                                        deliveryFee: value,
                                    });
                                    break;
                            }
                        }}
                    />
                )}

                {selectedTabIndex === 3 && (
                    <StoreSettingsContact
                        contactInfo={store.contactInfo}
                        onPropertyChanged={(property, value) => {
                            switch (property) {
                                case "contactInfo":
                                    setStore({ ...store, contactInfo: value });
                                    break;
                            }
                        }}
                    />
                )}

                {error && (
                    <Alert className="mt-4 mb-2" variant="danger">
                        {error.message}
                    </Alert>
                )}

                <Toast
                    className="w-100 my-2"
                    onClose={() => setShowSaved(false)}
                    bg="success"
                    show={showSaved}
                    delay={3000}
                    autohide
                >
                    <Toast.Body>
                        <div className="d-flex align-items-center">
                            {t("storeSettings_saved_notification")}
                        </div>
                    </Toast.Body>
                </Toast>
                <div className="bottom-toolbar">
                    <LoadingButton
                        size="lg"
                        variant="success"
                        onClick={save}
                        isLoading={isLoading}
                    >
                        {t("save")}
                    </LoadingButton>
                </div>
            </Form>
        </div>
    );
};

export default StoreSettings;
