import { t } from "i18next";
import React from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

interface LoadingButtonProps extends ButtonProps {
    isLoading: boolean;
    spinnerSize?: "sm" | undefined;
    loadingText?: string;
}

export const LoadingButton = ({
    isLoading,
    loadingText = t("loading"),
    spinnerSize,
    children,
    ...buttonProps
}: LoadingButtonProps) => {
    return (
        <Button
            {...buttonProps}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            disabled={isLoading}
        >
            {isLoading && (
                <Spinner
                    animation="border"
                    role="status"
                    size={spinnerSize}
                    className="me-2"
                >
                    <span className="sr-only">{loadingText}</span>
                </Spinner>
            )}
            {children}
        </Button>
    );
};
