import { useState } from "react";
import {
    Button,
    FormCheck,
    FormControl,
    FormGroup,
    ModalFooter,
} from "react-bootstrap";

import { t } from "i18next";

export interface ProductsOrderedFilterProps {
    filterBySupplier: boolean;
    filteredSupplier?: string;
    onApplyFilters: (filters: ProductsOrderedFilterProps) => void;
}
const ProductsOrderedFilterPanel = (props: ProductsOrderedFilterProps) => {
    const [values, setValues] = useState<ProductsOrderedFilterProps>(props);
    return (
        <div>
            <FormGroup>
                <FormCheck
                    label={t("productsOrderedFilterPanel_filterBySupplier")}
                    checked={values.filterBySupplier}
                    onChange={() =>
                        setValues({
                            ...props,
                            filterBySupplier: !values.filterBySupplier,
                        })
                    }
                />
                {values.filterBySupplier && (
                    <FormGroup className="mt-2 ms-4">
                        <FormControl
                            type="text"
                            placeholder={t("proveedor")}
                            value={values.filteredSupplier}
                            onChange={(e) =>
                                setValues({
                                    ...values,
                                    filteredSupplier: e.currentTarget.value,
                                })
                            }
                        />
                    </FormGroup>
                )}
            </FormGroup>

            <ModalFooter className="mt-4">
                <Button
                    variant="primary"
                    onClick={() => props.onApplyFilters(values)}
                >
                    {t("apply")}
                </Button>
            </ModalFooter>
        </div>
    );
};

export default ProductsOrderedFilterPanel;
