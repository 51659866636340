import { AxiosResponse } from "axios";
import { eProductTag, Product } from "../models/productModel";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";
import { convertImageUrlToBase64 } from "../helpers/imagesHelpers";

const axiosInstance = CreateAxiosInstance();

let _products: Product[] = [];

interface GetProductsResponse {
    success: boolean;
    message?: string;
    products: Product[];
}

export const GetProducts = async (): Promise<Product[]> => {
    // Returns cached products if available
    if (_products.length > 0) return _products;

    // Otherwise, fetch from API
    try {
        const response: AxiosResponse<GetProductsResponse> =
            await axiosInstance.get(BuildUrl("products?excludeDisabled=false"));
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al obtener productos"
            );

        _products = response.data.products;

        /* _products = response.data.products.map((product) => ({
      ...product,
      searchTermsArray: (product.searchTerms ?? "").split(","),
    }));
*/
        return _products;
    } catch (error) {
        const message = "Error al obtener los productos " + error;
        console.error(message);
        throw new Error(message);
    }
};

interface GetProductResponse {
    success: boolean;
    message?: string;
    product: Product;
}

export const GetProduct = async (
    productId: number
): Promise<Product | undefined> => {
    try {
        const response: AxiosResponse<GetProductResponse> =
            await axiosInstance.get(BuildUrl(`products/${productId}`));
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al obtener el producto"
            );

        let product = response.data.product;

        product.searchTermsArray = response.data.product.searchTermsArray = (
            response.data.product.searchTerms ?? ""
        ).split(",");

        return product;
    } catch (error) {
        const message = "Error al obtener los productos " + error;
        console.error(message);
        throw new Error(message);
    }
};

export const GetUnitsOfMeasure = (): string[] => {
    // Get the distincts units of measure from the products
    let unitsOfMeasure: string[] = [];
    _products.forEach((product) => {
        if (!unitsOfMeasure.includes(product.unitOfMeasure))
            unitsOfMeasure.push(product.unitOfMeasure);
    });
    return unitsOfMeasure;
};

export const GetSuppliers = (): string[] => {
    // Get the distincts suppliers from the products
    let suppliers: string[] = [];
    _products.forEach((product) => {
        if (product.supplierName && !suppliers.includes(product.supplierName))
            suppliers.push(product.supplierName);
    });
    return suppliers;
};

interface CreateProductResponse {
    success: boolean;
    message?: string;
    product: Product;
}

interface CreateProductRequest {
    id: number;
    name: string;
    searchTerms?: string;
    tag: eProductTag;
    order: number;
    thumbUrl: string;
    thumbBase64?: string;
    regularPrice?: number;
    price: number;
    cost: number;
    margin?: number;
    addTransactionCharge: boolean;
    unitOfMeasure: string;
    interval: number;
    productCategoryId: number;
    featured: boolean;
    disabled: boolean;
    outOfstock: boolean;
    locationCode: string;
    supplierName?: string;
    lastPurchasePrice?: number;
}

export const CreateProduct = async (product: Product) => {
    try {
        if (product.thumbUrl)
            product.thumbBase64 = await convertImageUrlToBase64(
                product.thumbUrl
            );

        product.searchTerms = product.searchTermsArray.join(",");

        const response: AxiosResponse<CreateProductResponse> =
            await axiosInstance.post(BuildUrl("products"), product);
        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al crear el producto"
            );

        _products.push(response.data.product);

        return response.data.product;
    } catch (error) {
        let message = error;
        if (error instanceof Error) message = error.message;
        console.log(message, error);
        throw new Error(message as string);
    }
};

interface UpdateProductResponse {
    success: boolean;
    message?: string;
    product: Product;
}

export const UpdateProduct = async (product: Product) => {
    try {
        if (product.thumbUrl)
            product.thumbBase64 = await convertImageUrlToBase64(
                product.thumbUrl
            );

        product.searchTerms = product.searchTermsArray.join(",");

        const response: AxiosResponse<UpdateProductResponse> =
            await axiosInstance.put(BuildUrl("products"), product);

        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al editar el producto"
            );

        let index = _products.findIndex((x) => x.id === product.id);
        if (index >= 0) _products[index] = product;

        return response.data.product;
    } catch (error) {
        let message = error;
        if (error instanceof Error) message = error.message;
        console.log(message, error);
        throw new Error(message as string);
    }
};

export const DeleteProduct = async (product: Product) => {
    try {
        const response: AxiosResponse<CreateProductResponse> =
            await axiosInstance.delete(BuildUrl(`products/${product.id}`));

        if (!response.data.success)
            throw new Error(
                response.data.message || "Error al eliminar el producto"
            );

        let index = _products.findIndex((x) => x.id === product.id);
        if (index >= 0) _products.splice(index, 1);

        return response.data.product;
    } catch (error) {
        let message = error;
        if (error instanceof Error) message = error.message;
        console.log(message, error);
        throw new Error(message as string);
    }
};

export type ProductTag = {
    id: eProductTag;
    name: string;
};

export const GetProductTags = (): ProductTag[] => [
    { id: eProductTag.None, name: "Ninguna" },
    { id: eProductTag.Limited, name: "Limitado" },
    { id: eProductTag.New, name: "Nuevo" },
    { id: eProductTag.Sale, name: "Oferta" },
];
