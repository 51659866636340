import { Fragment } from "react";

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Nav } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useStoreContext } from "../../api/context/StoreContext";
import { useSession } from "../../api/context/SessionContext";
import React from "react";
import Logo from "../Logo";
import LanguageSwitcher from "../LanguageSwitcher";
import { t } from "i18next";

interface SideBarProps {
    visible: boolean;
    onCloseButton: () => void;
}

const SideBar = (props: SideBarProps) => {
    const { store } = useStoreContext();
    const session = useSession();
    const navigate = useNavigate();

    if (!props.visible) return <Fragment />;

    function logOff() {
        session.logOut();
        navigate(".");
    }

    return (
        <div className="sidebar">
            <Button
                variant="outline"
                className="sidebar-closebtn"
                onClick={props.onCloseButton}
            >
                <FontAwesomeIcon icon={faClose} />
            </Button>
            <Logo height="30px" />

            <div id="principal" className="sidebar-submenu">
                <NavLink to="." onClick={props.onCloseButton}>
                    {t("sidebar_main")}
                </NavLink>
            </div>

            <div id="pedidos" className="sidebar-submenu">
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="orders"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_orders")}
                </NavLink>
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="products-ordered"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_ordered_products")}
                </NavLink>
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="fulfill-orders"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_fulfill_orders")}
                </NavLink>

                <NavLink
                    to="pack-orders"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_pack_orders")}
                </NavLink>

                <NavLink
                    to="ship-orders"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_ship_orders")}
                </NavLink>

                <NavLink
                    to="deliver-orders"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_deliver_orders")}
                </NavLink>

                <NavLink to="orders-receivable" onClick={props.onCloseButton}>
                    {t("sidebar_receivable_orders")}
                </NavLink>
            </div>

            <div id="coupons" className="sidebar-submenu">
                <NavLink to="coupons" onClick={props.onCloseButton}>
                    {t("sidebar_coupons")}
                </NavLink>
            </div>

            <div id="configuracion" className="sidebar-submenu">
                <NavLink
                    to="store/settings"
                    onClick={props.onCloseButton}
                    className="sidebar-submenu--border-bottom"
                >
                    {t("sidebar_store_settings")}
                </NavLink>

                <NavLink
                    to="products/categories"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_categories")}
                </NavLink>
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="products"
                    onClick={props.onCloseButton}
                >
                    {t("sidebar_products")}
                </NavLink>

                <NavLink
                    to="paymentmethods"
                    onClick={props.onCloseButton}
                    className="sidebar-submenu--border-bottom"
                >
                    {t("sidebar_payment_methods")}
                </NavLink>

                <NavLink
                    to="users"
                    onClick={props.onCloseButton}
                    className="sidebar-submenu--border-bottom"
                >
                    {t("sidebar_users")}
                </NavLink>

                <a
                    href="https://dashboard.stripe.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {t("stripe_dashboard")}
                </a>
            </div>

            <div
                id="logoff"
                className="sidebar-logoff d-flex justify-content-between"
            >
                <Button onClick={logOff} variant="outline">
                    {t("sidebar_logoff")}
                </Button>
                <LanguageSwitcher />
            </div>
        </div>
    );
};

export default SideBar;
