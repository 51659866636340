import { AxiosResponse } from "axios";
import { LocalDeliveryZone } from "../models/localDeliveryZone";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";

let _zones: LocalDeliveryZone[] = [];

const axiosInstance = CreateAxiosInstance();

interface GetLocalDeliveryZonesResponse {
  success: boolean;
  message?: string;
  localDeliveryZones: LocalDeliveryZone[];
}

export const GetLocalDeliveryZones = async (): Promise<LocalDeliveryZone[]> => {
  // Return cached data if available
  if (_zones.length > 0) return _zones;

  // Otherwise, get data from API
  try {
    const response: AxiosResponse<GetLocalDeliveryZonesResponse> =
      await axiosInstance.get(BuildUrl("local-delivery-zones"));
    if (!response.data.success)
      throw new Error(
        response.data.message ||
          "No fue posible cargar las zonas de entrega local"
      );

    _zones = response.data.localDeliveryZones;

    return _zones;
  } catch (error) {
    const message = "Error: " + error;
    console.log(message, error);
    throw new Error(message);
  }
};

interface CreateLocalDeliveryZoneResponse {
  success: boolean;
  message?: string;
  localDeliveryZone: LocalDeliveryZone;
}

export const CreateLocalDeliveryZone = async (
  localDeliveryZone: LocalDeliveryZone
) => {
  try {
    const response: AxiosResponse<CreateLocalDeliveryZoneResponse> =
      await axiosInstance.post(
        BuildUrl("local-delivery-zones"),
        localDeliveryZone
      );
    if (!response.data.success)
      throw new Error(response.data.message || "No fue posible dar de alta");

    // Reset cache to force reload
    _zones = [];

    return response.data.localDeliveryZone;
  } catch (error) {
    let message = error;
    if (error instanceof Error) message = error.message;
    console.log(message, error);
    throw new Error(message as string);
  }
};

interface UpdateLocalDeliveryZoneResponse {
  success: boolean;
  message?: string;
  localDeliveryZone: LocalDeliveryZone;
}

export const UpdateLocalDeliveryZone = async (
  localDeliveryZone: LocalDeliveryZone
) => {
  try {
    const response: AxiosResponse<UpdateLocalDeliveryZoneResponse> =
      await axiosInstance.put(
        BuildUrl("local-delivery-zones"),
        localDeliveryZone
      );
    if (!response.data.success)
      throw new Error(response.data.message || "No fue posible actualizar");

    // Reset cache to force reload
    _zones = [];

    return response.data.localDeliveryZone;
  } catch (error) {
    const message = "Error: " + error;
    console.log(message, error);
    throw new Error(message);
  }
};

interface DeleteLocalDeliveryZoneResponse {
  success: boolean;
  message?: string;
}

export const DeleteLocalDeliveryZone = async (
  localDeliveryZone: LocalDeliveryZone
) => {
  try {
    const response: AxiosResponse<DeleteLocalDeliveryZoneResponse> =
      await axiosInstance.delete(
        BuildUrl(`local-delivery-zones/${localDeliveryZone.id}`)
      );

    if (!response.data.success)
      throw new Error(response.data.message || "No fue posible eliminar");

    // Reset cache to force reload
    _zones = [];

    return response.data.success;
  } catch (error) {
    const message = "Error: " + error;
    console.log(message, error);
    throw new Error(message);
  }
};
