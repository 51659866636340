import { useEffect, useState } from "react";
import SearchEntry from "../../../components/SearchEntry";
import TitleBar from "../../../components/TitleBar";
import { Order, OrderPayment } from "../../../api/models/orderModel";
import {
    GetOrdersToDeliver,
    GetOrdersToDeliverViewModel,
    SetOrderDelivered,
} from "../../../api/services/ordersServices";
import LabelGroup from "../../../components/LabelGroup";
import DeliveryTypeBadge from "../Components/DeliveryTypeBadge";
import Tabs from "../../../components/Tab";
import DeliverOrder from "./DeliverOrder";
import { Button } from "react-bootstrap";
import OrderBalanceBadge from "../Components/OrderBalanceBadge";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import { useNavigate } from "react-router-dom";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import { checkPrimeSync } from "crypto";
import { useSession } from "../../../api/context/SessionContext";
import { t } from "i18next";

const DeliverOrdersPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [orders, setOrders] = useState<GetOrdersToDeliverViewModel[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [deliveringOrder, setDeliveringOrder] =
        useState<GetOrdersToDeliverViewModel>();
    const [filterDeliveryType, setFilterDeliveryType] = useState<number>(0);

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("95a9d8f7-1dff-41d2-afc9-f2a06677bcbc")
        ) {
            alert(t("no_permission"));
            navigate("../");
            return;
        }

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await GetOrdersToDeliver();
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const filterOrders = () => {
        let rValue = orders;

        if (filterDeliveryType > 0)
            rValue = rValue!.filter(
                (x) => x.deliveryType === filterDeliveryType - 1
            );

        if (searchText) {
            const searchUpper = searchText.toLocaleUpperCase();
            rValue = rValue!.filter(
                (x) =>
                    x.customerName.toLocaleUpperCase().includes(searchUpper) ||
                    x.shippingService
                        ?.toLocaleUpperCase()
                        .includes(searchUpper) ||
                    x.shippingTrackingGuide
                        ?.toLocaleUpperCase()
                        .includes(searchUpper)
            );
        }

        return rValue;
    };

    const handleOrderDelivered = async (
        orderId: number,
        payments: Array<OrderPayment>
    ) => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await SetOrderDelivered(orderId, payments);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setDeliveringOrder(undefined);
            await fetchData();
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        if (!deliveringOrder)
            return (
                <ErrorModal
                    errorMessage={errorMessage}
                    onRetryClick={fetchData}
                    onOkClick={() => setErrorMessage(undefined)}
                />
            );
        else
            return (
                <ErrorModal
                    errorMessage={errorMessage}
                    onOkClick={() => setErrorMessage(undefined)}
                />
            );

    if (deliveringOrder)
        return (
            <DeliverOrder
                order={deliveringOrder}
                onClose={() => setDeliveringOrder(undefined)}
                onDelivered={handleOrderDelivered}
            />
        );

    return (
        <div>
            <TitleBar title={t("deliverOrders_title")} />

            <SearchEntry onChange={(e) => setSearchText(e)} />

            <Tabs
                tabs={[
                    t("deliverOrders_filter_all"),
                    t("deliverOrders_filter_pickup"),
                    t("deliverOrders_filter_delivery"),
                ]}
                selectedTabIndex={filterDeliveryType}
                onSelectedTabChanged={(e) => setFilterDeliveryType(e)}
            />

            <div id="list">
                {filterOrders().map((x, i) => (
                    <div key={i} className="panel">
                        <div className="d-flex justify-content-between">
                            <LabelGroup caption={t("order")}>
                                {x.id.toString()}
                            </LabelGroup>
                            <LabelGroup caption={t("promised_delivery_date")}>
                                {x.promisedDeliveryDate}
                            </LabelGroup>
                        </div>
                        <div className="d-flex justify-content-start my-2">
                            <OrderBalanceBadge orderBalance={x.balance} />
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>
                                {x.customerName}
                                <DeliveryTypeBadge
                                    deliveryType={x.deliveryType}
                                />
                                {x.shippingService} {x.shippingTrackingGuide}
                            </div>
                            <div className="ms-4 mt-2">
                                <LabelGroup caption={t("bultos")}>
                                    <span className="fs-4">{x.packages}</span>
                                </LabelGroup>
                            </div>
                        </div>

                        <Button
                            variant="success"
                            className="mt-2"
                            onClick={() => setDeliveringOrder(x)}
                        >
                            {t("deliverOrders_deliver")}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeliverOrdersPage;
