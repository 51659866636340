import { Routes, Route } from "react-router-dom";
import { useSession } from "./api/context/SessionContext";
import CouponsListPage from "./pages/Coupons/CouponsListPage";
import LoginPage from "./pages/Authentication/LoginPage";
import OrdersListPage from "./pages/Orders/OrdersList/OrdersListPage";
import ProductsCategoriesPage from "./pages/Products/ProductsCategories/ProductsCategoriesPage";
import UpdateProductCategory from "./pages/Products/ProductsCategories/UpdateProductCategory";
import ProductsListPage from "./pages/Products/ProductsListPage";
import StoreSettings from "./pages/StoreSettings/StoreSettings";
import UpdateProduct from "./pages/Products/UpdateProduct";
import AddCoupons from "./pages/Coupons/AddCoupons";
import ViewOrder from "./pages/Orders/OrdersList/ViewOrder";
import ProductsOrderedListPage from "./pages/Orders/OrdersProducts/ProductsOrderedListPage";
import ViewOrdersProduct from "./pages/Orders/OrdersProducts/ViewOrdersProduct";
import FulfillOrdersPage from "./pages/Orders/FulfillOrders/FulfillOrdersPage";
import PackOrdersPage from "./pages/Orders/PackOrders/PackOrdersPage";
import ShipOrdersPage from "./pages/Orders/ShipOrders/ShipOrdersPage";
import DeliverOrdersPage from "./pages/Orders/DeliverOrders/DeliverOrdersPage";
import UsersPage from "./pages/Users/UsersPage";
import PaymentMethodsPage from "./pages/PaymentMethods/PaymentMethodsPage";
import UpdatePaymentMethod from "./pages/PaymentMethods/UpdatePaymentMethod";
import OrdersReceivablePage from "./pages/Orders/OrdersReceivable/OrdersReceivablePage";
import OrderReceivable from "./pages/Orders/OrdersReceivable/OrderReceivable";
import FulfillOrderPage from "./pages/Orders/FulfillOrders/FulfillOrderPage";
import PackOrder from "./pages/Orders/PackOrders/PackOrder";
import HomePage from "./pages/Home/HomePage";
import NonWorkingDaysPage from "./pages/StoreSettings/NonWorkingDays/NonWorkingDaysPage";
import UpdateNonWorkingDay from "./pages/StoreSettings/NonWorkingDays/UpdateNonWorkingDay";
import NavBar from "./components/navigation/navbar";
import UpdateUserPage from "./pages/Users/UpdateUserPage";
import RefreshStripeAccountLinkPage from "./pages/Stripe/RefreshStripeAccountLinkPage";
import StripeOnboardingCompleted from "./pages/Stripe/StripeOnboardingCompleted";

const App = () => {
    const session = useSession();

    if (!session.isLoggedIn()) return <LoginPage />;

    if (
        session.isLoggedIn() &&
        !session.hasDoor("0cb2acac-24eb-43e9-ace3-852ea7118128")
    ) {
        alert("No tiene permiso para ingresar a esta sección");
        session.logOut();
        return <LoginPage />;
    }

    return (
        <div>
            <NavBar />
            <div className="page background-nube">
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route
                        path="refresh-stripe-account-link"
                        element={<RefreshStripeAccountLinkPage />}
                    />
                    <Route
                        path="stripe-onboarding-completed"
                        element={<StripeOnboardingCompleted />}
                    />
                    <Route path="products" element={<ProductsListPage />} />
                    <Route
                        path="products/:productId"
                        element={<UpdateProduct />}
                    />
                    <Route path="products-add" element={<UpdateProduct />} />
                    <Route
                        path="products/categories/:categoryId"
                        element={<UpdateProductCategory />}
                    />
                    <Route
                        path="products/categories"
                        element={<ProductsCategoriesPage />}
                    />
                    <Route
                        path="products/categories-add"
                        element={<UpdateProductCategory />}
                    />

                    <Route
                        path="paymentmethods"
                        element={<PaymentMethodsPage />}
                    />
                    <Route
                        path="paymentmethods-add"
                        element={<UpdatePaymentMethod />}
                    />
                    <Route
                        path="paymentmethods/:paymentMethodId"
                        element={<UpdatePaymentMethod />}
                    />

                    <Route path="store/settings" element={<StoreSettings />} />
                    <Route path="coupons" element={<CouponsListPage />} />
                    <Route path="coupons-add" element={<AddCoupons />} />
                    <Route
                        path=":tenantCode/orders"
                        element={<OrdersListPage />}
                    />
                    <Route
                        path="products-ordered"
                        element={<ProductsOrderedListPage />}
                    />
                    <Route
                        path="products-ordered/:productId"
                        element={<ViewOrdersProduct />}
                    />
                    <Route path="orders" element={<OrdersListPage />} />
                    <Route path="orders/:orderId" element={<ViewOrder />} />
                    <Route
                        path="fulfill-orders"
                        element={<FulfillOrdersPage />}
                    />
                    <Route
                        path="fulfill-orders/:orderId"
                        element={<FulfillOrderPage />}
                    />
                    <Route path="pack-orders" element={<PackOrdersPage />} />
                    <Route path="pack-order/:orderId" element={<PackOrder />} />
                    <Route path="ship-orders" element={<ShipOrdersPage />} />
                    <Route
                        path="deliver-orders"
                        element={<DeliverOrdersPage />}
                    />
                    <Route
                        path="orders-receivable"
                        element={<OrdersReceivablePage />}
                    />
                    <Route
                        path="orders-receivable/:orderId"
                        element={<OrderReceivable />}
                    />

                    <Route
                        path="nonworkingdays"
                        element={<NonWorkingDaysPage />}
                    />
                    <Route
                        path="nonworkingdays/add"
                        element={<UpdateNonWorkingDay />}
                    />
                    <Route
                        path="nonworkingdays/:nonworkingdayId"
                        element={<UpdateNonWorkingDay />}
                    />

                    <Route path="users" element={<UsersPage />} />
                    <Route path="users-add" element={<UpdateUserPage />} />
                    <Route path="users/:userId" element={<UpdateUserPage />} />
                </Routes>
            </div>
        </div>
    );
};

export default App;
