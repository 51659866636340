import { useEffect, useState } from "react";
import { eShippingType } from "../../../api/models/orderModel";
import TitleBar from "../../../components/TitleBar";
import {
    GetOrdersToShip,
    GetOrdersToShipViewModel,
    SetOrderShipped,
} from "../../../api/services/ordersServices";
import SearchEntry from "../../../components/SearchEntry";
import LabelGroup from "../../../components/LabelGroup";
import AddressBox from "../../../components/AddressBox";
import OrderBalanceBadge from "../Components/OrderBalanceBadge";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import ShipOrder from "./ShipOrder";
import { useSession } from "../../../api/context/SessionContext";
import { t } from "i18next";

const ShipOrdersPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [orders, setOrders] = useState<GetOrdersToShipViewModel[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const [shippingOrder, setShippingOrder] =
        useState<GetOrdersToShipViewModel>();

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("9289bec3-ce5f-4574-8cb0-ffe52a5d2c6b")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
            return;
        }

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await GetOrdersToShip();
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const filterOrders = () => {
        if (!searchText) return orders;

        const searchUpper = searchText.toLocaleUpperCase();
        return orders!.filter((x) =>
            x.customerName.toLocaleUpperCase().includes(searchUpper)
        );
    };

    const handleOrderShipped = async (
        orderId: number,
        shippingType: eShippingType,
        shippingService: string,
        shippingTrackingGuide?: string
    ) => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);
            const response = await SetOrderShipped(
                orderId,
                session.getLoggedUser()!.userId,
                shippingType,
                shippingService,
                shippingTrackingGuide
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setShippingOrder(undefined);
            await fetchData();
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    if (shippingOrder)
        return (
            <ShipOrder
                order={shippingOrder}
                onClose={() => setShippingOrder(undefined)}
                onShipped={handleOrderShipped}
            />
        );

    return (
        <div>
            <TitleBar title={t("shipOrdersPage_title")} />

            <SearchEntry onChange={(e) => setSearchText(e)} />
            <div id="list">
                {filterOrders().map((x, i) => (
                    <div key={i} className="panel">
                        <div className="d-flex justify-content-between">
                            <LabelGroup caption={t("order")}>
                                {x.id.toString()}
                            </LabelGroup>
                            <LabelGroup caption={t("promised_delivery_date")}>
                                {x.promisedDeliveryDate}
                            </LabelGroup>
                        </div>
                        <div className="d-flex justify-content-start my-2">
                            <OrderBalanceBadge orderBalance={x.balance} />
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="flex-grow-1">
                                <div>{x.customerName}</div>
                                <AddressBox address={x.shippingAddress!} />
                            </div>
                            <div className="ms-4 mt-2">
                                <LabelGroup caption={t("bultos")}>
                                    <span className="fs-4">{x.packages}</span>
                                </LabelGroup>
                            </div>
                        </div>

                        <button
                            className="mt-2 btn btn-success"
                            onClick={() => setShippingOrder(x)}
                        >
                            {t("shipOrder_ship")}
                        </button>
                    </div>
                ))}
                {filterOrders().length === 0 && (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                        {t("shipOrdersPage_no_orders")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShipOrdersPage;
