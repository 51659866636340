import { useEffect, useState } from "react";
import { FormControl, FormControlProps } from "react-bootstrap";

interface AnyFormControlProps extends FormControlProps {
  [key: string]: any;
  onValueChanged: (value: number) => void;
}
const NumericInput = (props: AnyFormControlProps) => {
  const { as: AsComponent = "input", onValueChanged, value, ...rest } = props;

  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    const countDots = newValue.split(".").length - 1;
    if (countDots > 1) return;

    setInputValue(event.currentTarget.value);
    let sanitizedValue = newValue.replace(/[^0-9.]/g, "");
    if (!sanitizedValue) sanitizedValue = "0";
    if (onValueChanged) {
      onValueChanged(parseFloat(sanitizedValue));
    }
  };

  return (
    <FormControl
      as={AsComponent}
      value={inputValue}
      type="number"
      inputMode="decimal"
      pattern="[0-9]*"
      onChange={handleInputChange}
      {...rest}
    />
  );
};

export default NumericInput;
