// Joins two paths together, making sure there is only one slash between them
export const JoinUrl = (url: string, path: string) => {
  if (url.endsWith("/") && path.startsWith("/")) {
    return `${url}${path.substring(1)}`;
  } else if (!url.endsWith("/") && !path.startsWith("/")) {
    return `${url}/${path}`;
  } else {
    return `${url}${path}`;
  }
};
