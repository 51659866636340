interface TabsProps {
  tabs: string[];
  selectedTabIndex?: number;
  onSelectedTabChanged: (selectedTabIndex: number) => void;
}

const Tabs = ({
  tabs,
  selectedTabIndex = 0,
  onSelectedTabChanged,
}: TabsProps) => {
  function getTabClassName(tabIndex: number) {
    if (selectedTabIndex === tabIndex) return "selected";
    return "";
  }
  return (
    <div className="tabs">
      <ul>
        {tabs.map((x, i) => (
          <li
            key={i}
            className={getTabClassName(i)}
            onClick={() => onSelectedTabChanged(i)}
          >
            {x}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
