// Downloads an image from a url and converts it to base64 string
// If the url is empty or has the ?nc parameter, it returns undefined
export async function convertImageUrlToBase64(
    imageUrl: string
): Promise<string | undefined> {
    if (!imageUrl) return;

    // If the url has the ?nc parameter, it means that the image has not been uploaded from the client
    if (imageUrl.includes("?nc")) return;

    // Gets the image from the url
    const imgResponse = await fetch(imageUrl);
    const blob = await imgResponse.blob();

    // Converts the image to base64
    const base64 = await convertBlobToBase64(blob);
    return base64;
}

// Downloads a blob and converts it to base64 string
export async function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const base64data = reader.result as string;
            resolve(base64data);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(blob);
    });
}
