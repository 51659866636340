import React, { useState, useEffect } from "react";
import { RangoFechas, RangoFechasToString } from "../api/helpers/datesHelpers";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { t } from "i18next";

import { DateRange } from "react-date-range";
import { Button, Modal } from "react-bootstrap";
const locales = require("react-date-range/dist/locale");

interface Props {
    prefix?: string;
    rangoFechas: RangoFechas;
    onChange: (nuevoRango: RangoFechas) => void;
}

const DateRangeLink: React.FC<Props> = ({
    prefix = "",
    rangoFechas,
    onChange,
}) => {
    const [state, setState] = useState([
        {
            startDate: rangoFechas.Desde,
            endDate: rangoFechas.Hasta,
            key: "selection",
        },
    ]);
    const [showCalendar, setShowCalendar] = useState(false);
    const culture = localStorage.getItem("i18culture") || "es-MX";

    useEffect(() => {
        if (
            rangoFechas.Desde !== state[0].startDate ||
            rangoFechas.Hasta !== state[0].endDate
        ) {
            setState([
                {
                    startDate: rangoFechas.Desde,
                    endDate: rangoFechas.Hasta,
                    key: "selection",
                },
            ]);
        }
    }, [rangoFechas, showCalendar]);

    return (
        <div id="dateRangeLink" className="d-flex align-items-center">
            <Button
                variant="link"
                className="link text-center"
                onClick={() => setShowCalendar(!showCalendar)}
            >
                {prefix}
                {RangoFechasToString(rangoFechas)}
            </Button>
            {showCalendar && (
                <Modal show={showCalendar} centered backdrop="static">
                    <DateRange
                        locale={culture === "es-MX" ? locales.es : locales.en}
                        editableDateInputs={true}
                        onChange={(item) => {
                            if (item.selection)
                                setState([
                                    {
                                        startDate: item.selection
                                            .startDate as Date,
                                        endDate: item.selection.endDate as Date,
                                        key: "selection",
                                    },
                                ]);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                    />
                    <div className="footer">
                        <Button
                            className="mb-2 ms-2 me-2"
                            style={{ width: "96%" }}
                            size="sm"
                            variant="primary"
                            onClick={() => {
                                let nuevoRango = new RangoFechas(
                                    state[0].startDate,
                                    state[0].endDate
                                );
                                onChange(nuevoRango);
                                setShowCalendar(false);
                            }}
                        >
                            {t("apply")}
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default DateRangeLink;
