import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";
import { PaymentMethod } from "../models/paymentMethodModel";

const axiosInstance = CreateAxiosInstance();

const methods: PaymentMethod[] = [
  { id: 1, name: "Efectivo", referenceRequired: false },
  {
    id: 2,
    name: "Tarjeta",
    referenceRequired: true,
    referenceName: "No. Tarjeta",
  },
  {
    id: 3,
    name: "Vales de despensa",
    referenceRequired: true,
    referenceName: "No. Tarjeta",
  },
];

export type GetPaymentMethodsResponse = {
  success: boolean;
  message: string;
  paymentMethods: PaymentMethod[];
};

export const GetPaymentMethods =
  async (): Promise<GetPaymentMethodsResponse> => {
    try {
      const url = BuildUrl(`paymentmethods`);

      const response = await axiosInstance.get(url);
      return response.data as GetPaymentMethodsResponse;
    } catch (error) {
      console.log("Error getting payment methods", error);
      throw error;
    }
  };

export type GetPaymentMethodResponse = {
  success: boolean;
  message: string;
  paymentMethod: PaymentMethod;
};

export const GetPaymentMethod = async (id: number) => {
  try {
    const url = BuildUrl(`paymentmethods/${id}`);

    const response = await axiosInstance.get(url);
    return response.data as GetPaymentMethodResponse;
  } catch (error) {
    console.log(`Error getting payment method ${id}`, error);
    throw error;
  }
};

export type AddPaymentMethodResponse = {
  success: boolean;
  message: string;
};

export const AddPaymentMethod = async (paymentMethod: PaymentMethod) => {
  try {
    const url = BuildUrl(`paymentmethods`);

    const request = {
      name: paymentMethod.name,
      referenceRequired: paymentMethod.referenceRequired.toString(),
      referenceName: paymentMethod.referenceName,
    };

    const response = await axiosInstance.post(url, request);
    return response.data as AddPaymentMethodResponse;
  } catch (error) {
    console.log(`Error adding payment method ${paymentMethod}`, error);
    throw error;
  }
};

export type UpdatePaymentMethodResponse = {
  success: boolean;
  message: string;
};

export const UpdatePaymentMethod = async (paymentMethod: PaymentMethod) => {
  try {
    const url = BuildUrl(`paymentmethods/${paymentMethod.id}`);

    const request = {
      name: paymentMethod.name,
      referenceRequired: paymentMethod.referenceRequired.toString(),
      referenceName: paymentMethod.referenceName,
    };

    const response = await axiosInstance.put(url, request);
    return response.data as UpdatePaymentMethodResponse;
  } catch (error) {
    console.log(`Error adding payment method ${paymentMethod}`, error);
    throw error;
  }
};

export type DeletePaymentMethodResponse = {
  success: boolean;
  message: string;
};

export const DeletePaymentMethod = async (paymentMethod: PaymentMethod) => {
  try {
    const url = BuildUrl(`paymentmethods/${paymentMethod.id}`);

    const response = await axiosInstance.delete(url);
    return response.data as DeletePaymentMethodResponse;
  } catch (error) {
    console.log(`Error deleting payment method ${paymentMethod}`, error);
    throw error;
  }
};
