import { createImportSpecifier } from "typescript";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";

export const tenantId = window.location.pathname.split("/")[1]; //process.env.REACT_APP_TENANT_ID;

const axiosInstance = CreateAxiosInstance();

export const RefreshStripeAccountLink = async () => {
    try {
        const response = await axiosInstance.get(
            BuildUrl(`tenant/stripe/refresh-stripe-account-link/${tenantId}`)
        );
        if (response.status !== 200)
            throw new Error(
                response.data.message || "Error al obtener el link"
            );

        return response.data;
    } catch (error) {
        const message = "Error al obtener el link " + error;
        console.error(message);
        throw new Error(message);
    }
};
