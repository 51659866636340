import { t } from "i18next";
import { ChangeEvent, useRef } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface SelectImageProps {
    imageUrl: string;
    imageAlt?: string;
    imageWidth?: string;
    imageHeight?: string;
    buttonText?: string;
    buttonVariant?: string;
    multiple?: boolean;
    onImageChanged: (imagesUrl: string[]) => void;
}
const SelectImage = ({
    imageUrl,
    imageAlt = "",
    imageWidth = "100%",
    imageHeight = "100%",
    buttonText = t("select-image"),
    buttonVariant = "success",
    multiple = false,
    onImageChanged,
}: SelectImageProps) => {
    const logoImageFileInput = useRef<HTMLInputElement>(null);

    const tooltip = (
        <Tooltip id="img-tooltip">
            <strong>
                {t("select-image-specifications-0")} {imageWidth}
                {t("select-image-specifications-1")}
                {imageHeight}
                {t("select-image-specifications-2")}
            </strong>
        </Tooltip>
    );

    function handleLogoFileInputChange(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (!files) return;

        let urls: string[] = [];
        const filesArr = Array.from(files);
        filesArr.map((x) => urls.push(URL.createObjectURL(x)));

        onImageChanged(urls);
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex w-100 justify-content-center p-4">
                {!imageUrl ||
                    (imageUrl === "/img/no-image.png" && (
                        <div>
                            <svg width={imageWidth} height={imageHeight}>
                                <rect
                                    width="100%"
                                    height="100%"
                                    fill="#868e96"
                                />
                                <text
                                    x="50%"
                                    y="50%"
                                    fill="#dee2e6"
                                    dy=".3em"
                                    dominant-baseline="middle"
                                    textAnchor="middle"
                                    fontSize={".5rem"}
                                >
                                    {imageWidth} x {imageHeight}
                                </text>
                            </svg>
                        </div>
                    ))}
                {imageUrl && imageUrl !== "/img/no-image.png" && (
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <img
                            style={{
                                maxWidth: imageWidth,
                                maxHeight: imageHeight,
                            }}
                            className="mt-2 rounded"
                            src={imageUrl ? imageUrl : "/img/no-image.png"}
                            alt={imageAlt}
                        />
                    </OverlayTrigger>
                )}
            </div>
            <div className="d-grid gap-2">
                <OverlayTrigger placement="top" overlay={tooltip}>
                    <Button
                        variant={buttonVariant}
                        size="lg"
                        onClick={() => {
                            if (logoImageFileInput.current)
                                logoImageFileInput.current.click();
                        }}
                    >
                        {buttonText}
                    </Button>
                </OverlayTrigger>
            </div>
            <input
                type="file"
                title="Seleccionar imagen"
                ref={logoImageFileInput}
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
                multiple={multiple}
                onChange={handleLogoFileInputChange}
            />
        </div>
    );
};

export default SelectImage;
