import {
    Coupon,
    CreateCouponRequest,
    eCouponStatus,
} from "../models/couponModel";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";

const axiosInstance = CreateAxiosInstance();

export const GetCoupons = async (status: eCouponStatus): Promise<Coupon[]> => {
    try {
        const response = await axiosInstance.get(
            BuildUrl(`coupons?status=${status}`)
        );
        if (!response.data.success) throw new Error(response.data.message);

        const coupons = response.data.coupons as Coupon[];

        return coupons;
    } catch (error) {
        console.error(error);
        throw new Error("Error al obtener los cupones");
    }
};

export const CreateCoupons = async (request: CreateCouponRequest) => {
    try {
        const response = await axiosInstance.post(
            BuildUrl("coupons?status"),
            request
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error("Error al obtener los cupones");
    }
};

export const CancelCoupon = async (couponId: number) => {
    try {
        const response = await axiosInstance.put(
            BuildUrl(`coupons/${couponId}/cancel`)
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error("Error al cancelar el cupón");
    }
};
