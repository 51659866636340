import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { StoreContactInfo } from "../../api/models/storeModel";

import { t } from "i18next";

interface Props {
    contactInfo: StoreContactInfo;
    onPropertyChanged: (propertyName: string, value: any) => void;
}

const StoreSettingsContact = ({ contactInfo, onPropertyChanged }: Props) => {
    return (
        <div id="contact" className="panel">
            <FormGroup className="formGroup">
                <FormLabel>{t("storeSettingsContact_webpage")}</FormLabel>
                <FormControl
                    type="text"
                    placeholder={t("storeSettingsContact_webpage_placeholder")}
                    value={contactInfo.homePageUrl}
                    onChange={(e) =>
                        onPropertyChanged("contactInfo", {
                            ...contactInfo,
                            homePageUrl: e.currentTarget.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup className="formGroup">
                <FormLabel>{t("storeSettingsContact_email")}</FormLabel>
                <FormControl
                    type="text"
                    placeholder={t("storeSettingsContact_email_placeholder")}
                    value={contactInfo.email}
                    onChange={(e) =>
                        onPropertyChanged("contactInfo", {
                            ...contactInfo,
                            email: e.currentTarget.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup className="formGroup">
                <FormLabel>Facebook</FormLabel>
                <FormControl
                    type="text"
                    placeholder={t("storeSettingsContact_facebook_placeholder")}
                    value={contactInfo.facebook}
                    onChange={(e) =>
                        onPropertyChanged("contactInfo", {
                            ...contactInfo,
                            facebook: e.currentTarget.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup className="formGroup">
                <FormLabel>Instagram</FormLabel>
                <FormControl
                    type="text"
                    placeholder={t(
                        "storeSettingsContact_instagram_placeholder"
                    )}
                    value={contactInfo.instagram}
                    onChange={(e) =>
                        onPropertyChanged("contactInfo", {
                            ...contactInfo,
                            instagram: e.currentTarget.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup className="formGroup">
                <FormLabel>WhatsApp</FormLabel>
                <FormControl
                    type="text"
                    placeholder={t("storeSettingsContact_whatsapp_placeholder")}
                    value={contactInfo.whatsApp}
                    onChange={(e) =>
                        onPropertyChanged("contactInfo", {
                            ...contactInfo,
                            whatsApp: e.currentTarget.value,
                        })
                    }
                />
            </FormGroup>
        </div>
    );
};

export default StoreSettingsContact;
