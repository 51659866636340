import { useEffect, useState } from "react";
import TitleBar from "../../../components/TitleBar";
import SearchEntry from "../../../components/SearchEntry";
import {
    GetOrdersReceivable,
    GetOrdersReceivableViewModel,
} from "../../../api/services/ordersServices";
import LabelGroup from "../../../components/LabelGroup";
import { FormatDecimal } from "../../../api/helpers/formatters";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import { useSession } from "../../../api/context/SessionContext";
import { t } from "i18next";

const OrdersReceivablePage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [orders, setOrders] = useState<GetOrdersReceivableViewModel[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("e8411c3b-6e9e-4af1-a89a-872736f55f38")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
            return;
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await GetOrdersReceivable();
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const filterOrders = () => {
        if (!searchText) return orders;

        const searchUpper = searchText.toLocaleUpperCase();
        return orders!.filter((x) =>
            x.customerName.toLocaleUpperCase().includes(searchUpper) ||
            x.id.toString() === searchUpper
                ? x
                : null
        );
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <div>
            <TitleBar title={t("ordersReceivablePage_title")} />
            <SearchEntry onChange={(e) => setSearchText(e)} />
            <div id="list">
                {filterOrders().map((x, i) => (
                    <div key={i} className="panel">
                        <div className="d-flex justify-content-between">
                            <LabelGroup caption={t("order")}>
                                {x.id.toString()}
                            </LabelGroup>
                            <LabelGroup caption={t("promised_delivery_date")}>
                                {x.deliveredDate}
                            </LabelGroup>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>{x.customerName}</div>
                            <LabelGroup caption="Total">
                                <span className="fs-4">
                                    {FormatDecimal(x.totalFulfilled, true)}
                                </span>
                            </LabelGroup>
                            <LabelGroup caption={t("Saldo")}>
                                <span className="fs-4">
                                    {FormatDecimal(x.balance, true)}
                                </span>
                            </LabelGroup>
                        </div>

                        <Button
                            variant="success"
                            className="mt-2"
                            onClick={() =>
                                navigate(`../orders-receivable/${x.id}`)
                            }
                        >
                            {t("ordersReceivablePage_pay")}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrdersReceivablePage;
