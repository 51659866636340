import moment from "moment";
import "moment/locale/es-mx";

export const FormatDate = (date?: Date, showDOW?: boolean): string => {
  if (!date) return "";

  if (!showDOW) return moment(date).format("DD-MMM-YY");

  return moment(date).format("ddd, DD-MMM-YY");
};

export const FormatDateToString = (date?: Date) => {
  if (!date) return "";
  return date.toISOString().substr(0, 10) || "";
};

export const ConvertInputToDate = (isoDateString: string) => {
  const [year, month, day] = isoDateString.substr(0, 10).split("-").map(Number);

  const newDate = new Date(year, month - 1, day); // (Date.UTC(year, month - 1, day));

  return newDate;
};

export function FormatDecimal(number: number, currency: boolean = false) {
  let rValue = "";
  if (currency) rValue = "$";
  return rValue + commify(number.toFixed(2));
}

function commify(n: string) {
  var parts = n.split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "")
  );
}
