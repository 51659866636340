import { ReactNode } from "react";

interface LabelGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  caption: string;
  children: ReactNode;
}

const LabelGroup = (props: LabelGroupProps) => {
  const { className, ...rest } = props;
  return (
    <div
      className={`d-flex flex-column align-items-center ${className}`}
      {...rest}
    >
      <div style={{ margin: "0", fontSize: "0.7rem" }}>{props.caption}</div>
      <div className="w-100" style={{ margin: "0" }}>
        {props.children}
      </div>
    </div>
  );
};

export default LabelGroup;
