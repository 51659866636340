import { Alert, Button, FormGroup, FormLabel, Modal } from "react-bootstrap";
import {
    GetPaymentTypeNames,
    Order,
    ePaymentType,
} from "../../../api/models/orderModel";
import NumberSelector from "../../../components/NumberSelector";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    CalculateOrderFulfilledTotal,
    GetOrder,
    SetOrderPacked,
} from "../../../api/services/ordersServices";
import { FormatDecimal } from "../../../api/helpers/formatters";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import ErrorModal from "../../../components/ErrorModal";
import LoadingComponent from "../../../components/LoadingComponent";
import { useSession } from "../../../api/context/SessionContext";
import { t } from "i18next";

const PackOrder = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const session = useSession();

    const [order, setOrder] = useState<Order>();

    const [packages, setPackages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage("");

            const orderId = params.orderId ? parseInt(params.orderId) : -1;
            if (orderId === -1) {
                alert(t("packOrder_no_order_to_pack"));
                navigate("../pack-orders");
                return;
            }

            const response = await GetOrder(params.orderId!);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrder(response.order);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleEmpacar = async () => {
        setErrorMessage("");
        if (packages === 0) {
            setErrorMessage(t("packOrder_set_number_of_packages"));
            return;
        }

        if (
            !window.confirm(
                `${t("packOrder_confirm_prefix")} ${
                    order!.paymentType === ePaymentType.Card
                        ? t("packOrder_confirm_suffix")
                        : ""
                } ?`
            )
        )
            return;

        order!.packages = packages;

        try {
            setIsLoading(true);
            const response = await SetOrderPacked(
                order!.id,
                order!.packages,
                session.getLoggedUser()!.userId
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            navigate(getReturnUrl()); //TODO: Agregar query ReturnUrl
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const getReturnUrl = () => {
        const searchParams = new URLSearchParams(location.search);
        const returnUrl = searchParams.get("returnUrl");
        if (!returnUrl) return "../pack-orders";
        return returnUrl;
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage && !order)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onOkClick={() => navigate("../pack-orders")}
            />
        );

    if (!order) return <div />;

    return (
        <Modal show={true} onHide={() => navigate(getReturnUrl())}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("packOrder_title")} {order!.id}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="d-flex">
                        {t("customer")}: <strong>{order.customer.name}</strong>
                    </div>
                    <div className="d-flex">
                        {t("packOrder_total_packed")}:{" "}
                        <strong>
                            {FormatDecimal(
                                CalculateOrderFulfilledTotal(order),
                                true
                            )}
                        </strong>
                    </div>
                    <div className="d-flex">
                        {t("payment_method")}:{" "}
                        <strong>
                            {GetPaymentTypeNames()[order.paymentType]}
                        </strong>
                    </div>
                </div>
                <FormGroup className="mt-3">
                    <FormLabel>{t("bultos")}</FormLabel>
                    <NumberSelector
                        value={packages}
                        onChange={(e) => setPackages(e)}
                    />
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                {errorMessage && (
                    <Alert className="mt-4 mb-2 w-100" variant="danger">
                        {errorMessage}
                    </Alert>
                )}
                <Button variant="success" onClick={handleEmpacar}>
                    {t("pack")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PackOrder;
