import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { NonWorkingDate } from "../../../api/models/nonWorkingDateModel";
import { GetNonWorkingDates } from "../../../api/services/nonWorkingDateServices";
import { FormatDate } from "../../../api/helpers/formatters";
import LabelGroup from "../../../components/LabelGroup";
import TitleBar from "../../../components/TitleBar";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import { t } from "i18next";

const NonWorkingDaysPage = () => {
    const [nonWorkingDays, setNonWorkingDays] = useState<NonWorkingDate[]>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoadingError(undefined);
            setIsLoading(true);
            let result = await GetNonWorkingDates();
            setNonWorkingDays(result);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    {t("error_loading")} <br /> {t("please_try_again")}.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingComponent />;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => setLoadingError(undefined)}
            />
        );

    return (
        <div>
            <div id="toolbar" className="toolbar">
                <TitleBar
                    title={t("nonWordkingDaysPage_title")}
                    backUrl="../store/settings"
                />

                <Button
                    variant="success"
                    onClick={() => navigate("../nonworkingdays/add")}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>

            {nonWorkingDays.length === 0 && (
                <div className="d-flex justify-content-center">
                    <h3>{t("nonWordkingDaysPage_no_days")}</h3>
                </div>
            )}
            <div id="list">
                {nonWorkingDays.map((x, i) => (
                    <div
                        className="panel"
                        key={i}
                        onClick={() => navigate(`../nonworkingdays/${x.id}`)}
                    >
                        <span className="lead">{x.name}</span>
                        <div className="d-flex justify-content-between">
                            <LabelGroup
                                caption={t(
                                    "nonWordkingDaysPage_non_working_day"
                                )}
                            >
                                {FormatDate(x.date, true)}
                            </LabelGroup>

                            <LabelGroup caption={t("promised_delivery_date")}>
                                {FormatDate(x.deliveryDate, true)}
                            </LabelGroup>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NonWorkingDaysPage;
