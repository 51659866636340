import { Button, Modal } from "react-bootstrap";

interface ErrorModalProps {
  errorMessage: React.ReactNode;
  onOkClick?: () => void;
  onRetryClick?: () => void;
}
const ErrorModal = ({
  errorMessage,
  onOkClick,
  onRetryClick,
}: ErrorModalProps) => {
  return (
    <div className="modal show">
      <Modal show={true} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          {onRetryClick && (
            <Button variant="primary" onClick={onRetryClick}>
              Reintentar
            </Button>
          )}
          {onOkClick && (
            <Button variant="primary" onClick={onOkClick}>
              Aceptar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
  /* return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9998,
      }}
    >
      <Modal.Dialog centered style={{ zIndex: 9999 }}>
        <Modal.Header>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>

        <Modal.Body>{errorMessage}</Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={onOkClick}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  ); */
};

export default ErrorModal;
