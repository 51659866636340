import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { eActionType } from "../../../api/models/common";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { NonWorkingDate } from "../../../api/models/nonWorkingDateModel";
import {
    ConvertInputToDate,
    FormatDateToString,
} from "../../../api/helpers/formatters";
import { IError } from "../../../api/models/errorModel";
import {
    CreateNonWorkingDate,
    GetNonWorkingDate,
    UpdateNonWorkingDate as APIUpdateNonWorkingDate,
    DeleteNonWorkingDate,
} from "../../../api/services/nonWorkingDateServices";
import { LoadingButton } from "../../../components/LoadingButton";
import { t } from "i18next";

const UpdateNonWorkingDay = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [actionType, setActionType] = useState<eActionType>(
        eActionType.Create
    );
    const [nonWorkingDay, setNonWorkingDay] = useState<NonWorkingDate>({
        id: 0,
        name: "",
        date: new Date(),
        deliveryDate: new Date(),
    });
    const [error, setError] = useState<IError>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            if (params.nonworkingdayId) {
                //TODO: show loading and handle exception
                const id = parseInt(params.nonworkingdayId);
                const nwd = await GetNonWorkingDate(id);
                if (!nwd) console.log("record not found");
                else {
                    setNonWorkingDay(nwd);
                    setActionType(eActionType.Update);
                }
            }
        };
        fetchData();
    }, []);

    const handleSave = async () => {
        if (!validate()) return;

        try {
            setLoading(true);
            if (actionType === eActionType.Create)
                await CreateNonWorkingDate(nonWorkingDay);
            if (actionType === eActionType.Update)
                await APIUpdateNonWorkingDate(nonWorkingDay);

            navigate("../nonworkingdays");
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string, property: "" });
        } finally {
            setLoading(false);
        }
    };

    const validate = () => {
        if (!nonWorkingDay.name) {
            setError({
                message: t("nonWordkingDaysPage_name_required"),
                property: "name",
            });
            return false;
        }

        setError(undefined);
        return true;
    };

    const handleDelete = async () => {
        if (!window.confirm(t("confirm_delete"))) return;

        try {
            setLoading(true);
            await DeleteNonWorkingDate(nonWorkingDay);

            navigate("../nonworkingdays");
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string, property: "" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="modal show"
            style={{ display: "block", position: "initial" }}
        >
            <Modal.Dialog>
                <Modal.Header
                    closeButton
                    onHide={() => navigate("../nonworkingdays")}
                >
                    <Modal.Title>
                        {actionType === eActionType.Create && (
                            <span>{t("agregar")} </span>
                        )}
                        {actionType === eActionType.Update && (
                            <span>{t("edit")} </span>
                        )}
                        {t("nonWordkingDaysPage_non_working_day")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>{t("name")}</Form.Label>
                            <Form.Control
                                type="text"
                                isInvalid={error?.property === "name"}
                                placeholder={t(
                                    "nonWordkingDaysPage_name_placeholder"
                                )}
                                value={nonWorkingDay.name}
                                onChange={(e) =>
                                    setNonWorkingDay({
                                        ...nonWorkingDay,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Label>
                                {t("nonWordkingDaysPage_non_working_day")}
                            </Form.Label>
                            <Form.Control
                                type="date"
                                value={FormatDateToString(nonWorkingDay.date)}
                                onChange={(e) =>
                                    setNonWorkingDay({
                                        ...nonWorkingDay,
                                        date: ConvertInputToDate(
                                            e.currentTarget.value
                                        ),
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group className="mt-2">
                            <Form.Label>
                                {t("nonWordkingDaysPage_delivery_day")}
                            </Form.Label>
                            <Form.Control
                                type="date"
                                value={FormatDateToString(
                                    nonWorkingDay.deliveryDate
                                )}
                                onChange={(e) =>
                                    setNonWorkingDay({
                                        ...nonWorkingDay,
                                        deliveryDate: ConvertInputToDate(
                                            e.currentTarget.value
                                        ),
                                    })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {error && (
                        <Alert className="mt-4 mb-2 w-100" variant="danger">
                            {error.message}
                        </Alert>
                    )}
                    <div className="bottom-toolbar">
                        {actionType === eActionType.Update && (
                            <LoadingButton
                                isLoading={loading}
                                loadingText={`${t("deleting")}...`}
                                size="lg"
                                variant="outline-danger"
                                onClick={handleDelete}
                            >
                                {t("eliminar")}
                            </LoadingButton>
                        )}
                        <LoadingButton
                            isLoading={loading}
                            loadingText={`${t("saving")}...`}
                            onClick={async () => await handleSave()}
                            size="lg"
                            variant="success"
                        >
                            {t("save")}
                        </LoadingButton>
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
};

export default UpdateNonWorkingDay;
