import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  children?: React.ReactNode;
  backUrl?: string;
}
const TitleBar = ({ title, backUrl, children }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <h1>
        {backUrl && (
          <FontAwesomeIcon
            className="text-primary"
            icon={faChevronLeft}
            onClick={() =>
              backUrl === "-1" ? navigate(-1) : navigate(backUrl)
            }
          />
        )}
        {title}
      </h1>
      {children}
    </div>
  );
};

export default TitleBar;
