import { t } from "i18next";
import { Link } from "react-router-dom";

const HomePage = () => {
    return (
        <div className="pt-4">
            {/* <h1>¿No sabes ni por dónde empezar?</h1>
        <img
        style={{ maxWidth: "95%" }}
        src="https://www.eldiariodechihuahua.mx/chih/media/uploads/galeria/2022/06/30/20220630082221359-0-1946203.jpg"
      /> */}
            <h2>{t("home_title")}:</h2>
            <ol>
                <li>
                    <Link to="store/settings">{t("home_set_store")}</Link>
                </li>
                <li>
                    <Link to="products/categories">
                        {t("home_create_categories")}
                    </Link>
                </li>
                <li>
                    <Link to="products/">{t("home_create_products")}</Link>
                </li>
            </ol>

            <h2>{t("home_operation_guide")}</h2>
            <ol>
                <li>
                    <Link to="orders">{t("home_view_orders")}</Link>
                </li>

                <li>
                    <Link to="fulfill-orders">{t("home_fulfill_orders")}</Link>
                </li>
            </ol>
        </div>
    );
};

export default HomePage;
