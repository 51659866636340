import { t } from "i18next";
import { FunctionComponent, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";

interface SearchEntryProps {
    onChange: (value: string) => void;
    value?: string;
    autoFocus?: boolean;
    className?: string;
}

const SearchEntry: FunctionComponent<SearchEntryProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (props.autoFocus && inputRef.current)
            (inputRef.current as HTMLElement).focus();
    }, []);

    return (
        <div
            id="SearchEntry"
            className={
                "d-flex flex-grow-1 " + (props.className ? props.className : "")
            }
        >
            <Form.Control
                ref={inputRef}
                placeholder={t("search_placeholder")}
                value={props.value}
                type="search"
                onChange={(e) => props.onChange(e.target.value)}
            />
        </div>
    );
};

export default SearchEntry;
