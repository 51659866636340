import { faPlus, faSubtract } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumericInput from "./NumericInput";
import { Button } from "react-bootstrap";

interface Props {
  value: number;
  onChange: (value: number) => void;
  numericInputClassName?: string;
}
const NumberSelector = ({ value, onChange, numericInputClassName }: Props) => {
  return (
    <div className="d-flex justify-content-between">
      <Button
        variant="warning"
        className="me-2"
        disabled={value === 0}
        onClick={() => {
          value -= 1;
          onChange(value);
        }}
      >
        <FontAwesomeIcon icon={faSubtract} />
      </Button>
      <NumericInput
        className={numericInputClassName}
        value={value}
        onValueChanged={(e: number) => onChange(e)}
      />
      <Button
        variant="success"
        className="ms-2"
        onClick={() => {
          value += 1;
          onChange(value);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};

export default NumberSelector;
