import { useEffect, useState } from "react";
import SearchEntry from "../../components/SearchEntry";
import TitleBar from "../../components/TitleBar";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PaymentMethod } from "../../api/models/paymentMethodModel";
import { GetPaymentMethods } from "../../api/services/paymentMethodServices";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";
import { GetErrorMessage } from "../../api/helpers/apiHelpers";
import { useSession } from "../../api/context/SessionContext";
import { t } from "i18next";

const PaymentMethodsPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [methods, setMethods] = useState<PaymentMethod[]>([]);
    const [searchText, setSearchText] = useState<string>();

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("43aa8f15-2b30-452f-8abe-40e6819be670")
        ) {
            alert(t("dont_have_permission"));
            navigate("../");
        }

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await GetPaymentMethods();
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setMethods(response.paymentMethods);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <div>
            <TitleBar title={t("paymentMethodsPage_title")} />

            <SearchEntry onChange={(e) => setSearchText(e)} />

            <div id="toolbar" className="toolbar">
                <Button
                    variant="success"
                    onClick={() => navigate("../paymentmethods-add")}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>

            <div id="list">
                {methods.map((x, i) => (
                    <div key={i}>
                        <div
                            className="panel"
                            onClick={() =>
                                navigate(`../paymentmethods/${x.id}`)
                            }
                        >
                            {x.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaymentMethodsPage;
