import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Address } from "../api/models/orderModel";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

interface Props {
  address: Address;
  className?: string;
}
const AddressBox = ({ address, className }: Props) => {
  return (
    <div className={`d-flex justify-content-between ${className}`}>
      <div className="smallText ms-3">
        <p>
          {address.street} {address.outsideNumber} {address.innerNumber && "-"}
          {address.innerNumber}
        </p>
        <p>{address.neighborhood}</p>
        <p>
          {address.city}, {address.state}
        </p>
        <p>{address.zipCode}</p>
      </div>

      <Button
        style={{ height: "3rem", alignSelf: "center" }}
        size="lg"
        variant="outline-primary"
        onClick={() =>
          window.open(
            `https://www.google.com/maps/@${address?.lat},${address?.lng},18z`
          )
        }
      >
        <FontAwesomeIcon icon={faLocationDot} />
      </Button>
    </div>
  );
};

export default AddressBox;
