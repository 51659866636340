import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { IError } from "../../api/models/errorModel";
import SelectImage from "../../components/SelectImage";
import RemoveImageBackground from "../../components/RemoveImageBackground";

import { t } from "i18next";

interface Props {
    storeName: string;
    logoUrl?: string;
    error?: IError;
    onPropertyChanged: (propertyName: string, value: any) => void;
}

const StoreSettingsNameLogo = ({
    storeName,
    logoUrl,
    error,
    onPropertyChanged,
}: Props) => {
    return (
        <div>
            <div id="name" className="panel">
                <FormGroup>
                    <FormLabel>{t("name")}</FormLabel>
                    <FormControl
                        type="text"
                        placeholder={t(
                            "storeSettingsNameLogo_store_name_placeholder"
                        )}
                        value={storeName}
                        isInvalid={error?.property === "storeName"}
                        onChange={(e) =>
                            onPropertyChanged(
                                "storeName",
                                e.currentTarget.value
                            )
                        }
                    />
                </FormGroup>

                <FormGroup className="mt-2">
                    <FormLabel>
                        {t("storeSettingsNameLogo_store_logo")}
                    </FormLabel>
                    <SelectImage
                        imageUrl={logoUrl ? logoUrl : "/img/no-image.png"}
                        imageWidth="50px"
                        imageHeight="50px"
                        imageAlt="Logo"
                        buttonVariant="primary"
                        onImageChanged={(imagesUrl) =>
                            onPropertyChanged("logoUrl", imagesUrl[0])
                        }
                    />
                    <RemoveImageBackground
                        sourceImageUrl={logoUrl ? logoUrl : "/img/no-image.png"}
                        onBackgroundRemoved={(url) =>
                            onPropertyChanged("logoUrl", url)
                        }
                        className="w-100 my-2"
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default StoreSettingsNameLogo;
