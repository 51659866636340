import { AxiosResponse } from "axios";
import { NonWorkingDate } from "../models/nonWorkingDateModel";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";

const axiosInstance = CreateAxiosInstance();

let _nonWorkingDates: NonWorkingDate[] = [];

interface GetNonWorkingDatesResponse {
  success: boolean;
  message?: string;
  nonWorkingDates: NonWorkingDate[];
}

// Returns a list of all non working dates
export const GetNonWorkingDates = async (): Promise<NonWorkingDate[]> => {
  // Return cached data if available
  if (_nonWorkingDates.length > 0) return _nonWorkingDates;

  // Otherwise, get data from API
  try {
    const response: AxiosResponse<GetNonWorkingDatesResponse> =
      await axiosInstance.get(BuildUrl("non-working-dates"));
    if (!response.data.success)
      throw new Error(
        response.data.message || "Error al obtener las fechas no laborables"
      );

    _nonWorkingDates = response.data.nonWorkingDates;

    // Convert _nonworkingDates to Date objects
    _nonWorkingDates.forEach((x) => {
      x.date = new Date(Date.parse(x.date as any));
      x.deliveryDate = new Date(Date.parse(x.deliveryDate as any));
    });

    return _nonWorkingDates;
  } catch (error) {
    let message = error;
    if (error instanceof Error) message = error.message;
    console.log(message, error);
    throw new Error(message as string);
  }
};

// Returns a single non working date by id
export const GetNonWorkingDate = (id: number) =>
  GetNonWorkingDates().then((nonWorkingDates) =>
    nonWorkingDates.find((x) => x.id === id)
  );

interface CreateNonWorkingDateResponse {
  success: boolean;
  message?: string;
  nonWorkingDate: NonWorkingDate;
}

// Creates a new non working date
export const CreateNonWorkingDate = async (nonWorkingDate: NonWorkingDate) => {
  try {
    const response: AxiosResponse<CreateNonWorkingDateResponse> =
      await axiosInstance.post(BuildUrl("non-working-dates"), nonWorkingDate);
    if (!response.data.success)
      throw new Error(response.data.message || "No fue posible dar de alta");

    // Reset cache to force reload
    _nonWorkingDates = [];

    // Returns the new non working date
    return response.data.nonWorkingDate;
  } catch (error) {
    let message = error;
    if (error instanceof Error) message = error.message;
    console.log(message, error);
    throw new Error(message as string);
  }
};

interface UpdateNonWorkingDateResponse {
  success: boolean;
  message?: string;
  nonWorkingDate: NonWorkingDate;
}

// Updates an existing non working date
export const UpdateNonWorkingDate = async (nonWorkingDate: NonWorkingDate) => {
  try {
    const response: AxiosResponse<UpdateNonWorkingDateResponse> =
      await axiosInstance.put(
        BuildUrl(`non-working-dates/${nonWorkingDate.id}`),
        nonWorkingDate
      );
    if (!response.data.success)
      throw new Error(response.data.message || "No fue posible editar");

    // Reset cache to force reload
    _nonWorkingDates = [];

    // Returns the updated non working date
    return response.data.nonWorkingDate;
  } catch (error) {
    let message = error;
    if (error instanceof Error) message = error.message;
    console.log(message, error);
    throw new Error(message as string);
  }
};

interface DeleteNonWorkingDateResponse {
  success: boolean;
  message?: string;
}

// Deletes an existing non working date
export const DeleteNonWorkingDate = async (nonWorkingDate: NonWorkingDate) => {
  try {
    const response: AxiosResponse<DeleteNonWorkingDateResponse> =
      await axiosInstance.delete(
        BuildUrl(`non-working-dates/${nonWorkingDate.id}`)
      );

    if (!response.data.success)
      throw new Error(response.data.message || "No fue posible eliminar");

    // Reset cache to force reload
    _nonWorkingDates = [];

    return true;
  } catch (error) {
    let message = error;
    if (error instanceof Error) message = error.message;
    console.log(message, error);
    throw new Error(message as string);
  }
};
