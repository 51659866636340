import { useContext, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import moment, { isDate } from "moment";
import Tabs from "../../components/Tab";
import { FormatDate } from "../../api/helpers/formatters";
import { useNavigate } from "react-router-dom";
import { IError } from "../../api/models/errorModel";
import TitleBar from "../../components/TitleBar";
import { CreateCouponRequest } from "../../api/models/couponModel";
import { CreateCoupons } from "../../api/services/couponServices";
import { useSession } from "../../api/context/SessionContext";
import { LoadingButton } from "../../components/LoadingButton";
import NumericInput from "../../components/NumericInput";
import { t } from "i18next";

type FormValues = {
    numberOfCoupons: number;
    description: string;
    amount: number;
    isPercentaje: boolean;
    minimumPurchaseAmount: number;
    dueDate: Date;
};

const AddCoupons = () => {
    const [formValues, setFormValues] = useState<FormValues>({
        numberOfCoupons: 0,
        amount: 0,
        isPercentaje: false,
        minimumPurchaseAmount: 0,
        description: "",
        dueDate: moment().add(1, "days").toDate(),
    });

    const [error, setError] = useState<IError>();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        // Remove hours and adds a day to solve timezone conflicts
        const newValue = name.includes("Date")
            ? moment(new Date(value)).startOf("day").add(1, "days").toDate()
            : value;

        if (isDate(newValue)) {
            console.log("newValue", newValue);
            console.log("newValue");
            console.log("fmt", FormatDate(newValue));
        }

        setFormValues({ ...formValues, [name]: newValue });
    };

    const session = useSession();

    console.log("session: ", session.getLoggedUser());

    const crearCupones = async () => {
        if (!validate()) return;
        setError(undefined);

        try {
            setIsLoading(true);
            const request: CreateCouponRequest = {
                amount: formValues.amount,
                description: formValues.description,
                dueDate: formValues.dueDate,
                isPercentaje: formValues.isPercentaje,
                minimumPurchaseAmount: formValues.minimumPurchaseAmount,
                numberOfCoupons: formValues.numberOfCoupons,
                issuedBy: session.getLoggedUser()!.userId,
            };

            var response = await CreateCoupons(request);
            if (!response.success) throw new Error(response.message);
            else navigate(-1);
        } catch (error: any) {
            setError({ message: error.message, property: undefined });
        } finally {
            setIsLoading(false);
        }
    };

    function validate() {
        if (formValues.numberOfCoupons <= 0) {
            setError({
                message: t("addCoupons_numberOfCoupons_error"),
                property: "numberOfCoupons",
            });
            return false;
        }

        if (!formValues.description) {
            setError({
                message: t("addCoupons_description_error"),
                property: "description",
            });
            return false;
        }

        if (formValues.amount <= 0) {
            if (formValues.isPercentaje)
                setError({
                    message: t("addCoupons_percentaje_error"),
                    property: "percentaje",
                });
            else
                setError({
                    message: t("addCoupons_amount_error"),
                    property: "amount",
                });
            return false;
        }

        setError(undefined);
        return true;
    }

    return (
        <div id="addCoupons">
            <TitleBar title={t("addCoupons_title")} backUrl="-1" />

            <Form className="panel">
                <Form.Group id="numberOfCoupons">
                    <Form.Label>{t("addCoupons_numberOfCoupons")}</Form.Label>
                    <NumericInput
                        min="0"
                        name="numberOfCoupons"
                        isInvalid={error?.property === "numberOfCoupons"}
                        value={formValues.numberOfCoupons}
                        onValueChanged={(e) =>
                            setFormValues({ ...formValues, numberOfCoupons: e })
                        }
                    />
                </Form.Group>
                <Form.Group id="description">
                    <Form.Label>{t("description")}:</Form.Label>
                    <Form.Control
                        type="text"
                        name="description"
                        isInvalid={error?.property === "description"}
                        value={formValues.description}
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <div id="isPercentaje" className="mt-2">
                    <Tabs
                        tabs={[t("percentaje"), t("amount")]}
                        selectedTabIndex={formValues.isPercentaje ? 0 : 1}
                        onSelectedTabChanged={(e) =>
                            setFormValues({
                                ...formValues,
                                isPercentaje: e === 0,
                            })
                        }
                    />
                </div>
                <Form.Group id="amount">
                    <Form.Label>{t("discount")}:</Form.Label>
                    <div className="d-flex align-items-center">
                        {!formValues.isPercentaje && (
                            <span className="me-2">$</span>
                        )}
                        <NumericInput
                            type="number"
                            name="amount"
                            isInvalid={error?.property === "amount"}
                            value={formValues.amount}
                            onValueChanged={(e) =>
                                setFormValues({ ...formValues, amount: e })
                            }
                        />
                        {formValues.isPercentaje && (
                            <span className="ms-2">%</span>
                        )}
                    </div>
                </Form.Group>
                <Form.Group id="minimumPurchaseAmount">
                    <Form.Label>{t("minimum_amount")}:</Form.Label>
                    <NumericInput
                        type="number"
                        name="minimumPurchaseAmount"
                        value={formValues.minimumPurchaseAmount}
                        onValueChanged={(e) =>
                            setFormValues({
                                ...formValues,
                                minimumPurchaseAmount: e,
                            })
                        }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t("due_date")}:</Form.Label>
                    <Form.Control
                        type="date"
                        name="dueDate"
                        value={formValues.dueDate
                            .toISOString()
                            .substring(0, 10)}
                        onChange={handleInputChange}
                    />
                </Form.Group>
            </Form>

            {error && (
                <Alert className="mt-4 mb-2" variant="danger">
                    {error.message}
                </Alert>
            )}

            <div className="bottom-toolbar">
                <LoadingButton
                    isLoading={isLoading}
                    size="lg"
                    variant="success"
                    onClick={() => crearCupones()}
                >
                    {t("addCoupons_title")}
                </LoadingButton>
            </div>
        </div>
    );
};

export default AddCoupons;
