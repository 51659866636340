import { OrderPayment } from "../../../api/models/orderModel";
import { FormatDecimal } from "../../../api/helpers/formatters";
import { useEffect, useState } from "react";
import { PrimeOrderPayments } from "../../../api/services/ordersServices";
import NumericInput from "../../../components/NumericInput";
import { Form } from "react-bootstrap";
import { t } from "i18next";

interface Props {
    orderTotal: number;
    orderPayments?: Array<OrderPayment>;
    onChange: (orderPayments: Array<OrderPayment>) => void;
}
const OrderPayments = ({ orderTotal, orderPayments = [], onChange }: Props) => {
    const [payments, setPayments] =
        useState<Array<OrderPayment>>(orderPayments);

    useEffect(() => {
        const fetchData = async () => {
            if (!payments || payments.length === 0) {
                const primed = await PrimeOrderPayments();
                setPayments(primed);
            }
        };

        fetchData();
    }, []);

    function handleChangeAmount(newValue: number, paymentIndex: number): void {
        payments![paymentIndex].amount = newValue;
        setPayments([...payments]);

        onChange(payments);
    }

    function handleChangePaymentReference(
        newValue: string,
        paymentIndex: number
    ): void {
        payments![paymentIndex].paymentReference = newValue;
        setPayments([...payments]);

        onChange(payments);
    }

    const sumPayments = payments.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.amount;
    }, 0);

    const balance = () => orderTotal - sumPayments;

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t("payment_method")}</th>
                        <th className="text-end">{t("amount")}</th>
                    </tr>
                </thead>
                <tbody>
                    {payments?.map((x, i) => (
                        <>
                            <tr key={i}>
                                <td>{x.paymentMethod.name}</td>
                                <td className="text-end">
                                    <NumericInput
                                        className="text-end"
                                        value={x.amount === 0 ? "" : x.amount}
                                        onValueChanged={(e: number) =>
                                            handleChangeAmount(e, i)
                                        }
                                    />
                                </td>
                            </tr>
                            {x.paymentMethod.referenceRequired && (
                                <tr>
                                    <td>
                                        {!x.paymentMethod.referenceName
                                            ? t("refrence")
                                            : x.paymentMethod.referenceName}
                                    </td>
                                    <td className="text-end">
                                        <Form.Control
                                            aria-label={t("refrence")}
                                            type="text"
                                            value={x.paymentReference}
                                            onChange={(e) =>
                                                handleChangePaymentReference(
                                                    e.currentTarget.value,
                                                    i
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>{t("total_payments")}:</td>
                        <td className="text-end">
                            {FormatDecimal(sumPayments, true)}
                        </td>
                    </tr>
                    <tr>
                        <th>{t("Saldo")}:</th>
                        <th
                            className={`text-end ${
                                balance() > 0 ? "text-danger" : "text-success"
                            }`}
                        >
                            {FormatDecimal(balance(), true)}
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default OrderPayments;
