import { useTranslation } from "react-i18next";

export class RangoFechas {
    Desde: Date;
    Hasta: Date;

    constructor(desde: Date | null = null, hasta: Date | null = null) {
        if (!desde || !hasta) {
            desde = GetFechaDesde(new Date());
            hasta = GetFechaHasta(new Date());
        }

        this.Desde = desde;
        this.Hasta = hasta;
    }
}

const culture = localStorage.getItem("i18culture") || "es-MX";

export function RangoFechasToString(rangoFechas: RangoFechas) {
    let desde = GetFechaDesde(rangoFechas.Desde);
    let hasta = GetFechaDesde(rangoFechas.Hasta);
    if (desde.getTime() === hasta.getTime()) {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        } as const;

        return `${desde.toLocaleDateString(culture, options)}`;
    }

    if (
        desde.getDate() === 1 &&
        desde.getMonth() === 0 &&
        hasta.getDate() === 31 &&
        hasta.getMonth() === 11 &&
        desde.getFullYear() === hasta.getFullYear()
    ) {
        return `del año ${desde.getFullYear()}`;
    }
    // Mismo mes y mismo año
    if (
        desde.getMonth() === hasta.getMonth() &&
        desde.getFullYear() === hasta.getFullYear()
    ) {
        // Primer y ultimo dia del mes
        if (
            desde.getDate() === 1 &&
            hasta.getDate() ===
                daysInMonth(hasta.getMonth(), hasta.getFullYear())
        ) {
            return desde.toLocaleDateString(culture, {
                month: "long",
                year: "numeric",
            });
        }
        // Otros dias que no cubren todo el mes
        return `${desde.getDate()} al ${hasta.getDate()} de ${desde.toLocaleDateString(
            culture,
            { month: "long", year: "numeric" }
        )}`;
    } else {
        // Mismo año, distinto mes
        if (desde.getFullYear() === hasta.getFullYear()) {
            return `${desde.toLocaleDateString(culture, {
                day: "numeric",
                month: "long",
            })} al ${hasta.toLocaleDateString(culture, {
                day: "numeric",
                month: "long",
                year: "numeric",
            })}`;
        }
    }

    // Distinto mes y año
    return `${desde.toLocaleDateString(culture, {
        day: "numeric",
        month: "long",
        year: "numeric",
    })} al ${hasta.toLocaleDateString(culture, {
        day: "numeric",
        month: "long",
        year: "numeric",
    })}`;
}
export function FechaEntregaDefault(): Date {
    // Por omisión sugiere la fecha de entrega de mañana
    let fechaEntrega = new Date();
    fechaEntrega.setDate(fechaEntrega.getDate() + 1);

    // Si la fecha de entrega es domingo, pasa al lunes
    if (fechaEntrega.getDay() === 0)
        fechaEntrega.setDate(fechaEntrega.getDate() + 1);

    return fechaEntrega;
}

export function GetPrimerDiaDelMes(): Date {
    let hoy = new Date();
    return new Date(hoy.getFullYear(), hoy.getMonth(), 1);
}

export function GetFechaDesde(fecha: Date): Date {
    return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
}

export function GetFechaHasta(fecha: Date): Date {
    return new Date(
        fecha.getFullYear(),
        fecha.getMonth(),
        fecha.getDate(),
        23,
        59,
        59
    );
}

export function daysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
}

export function formatShortDate(fecha: string | number | Date): string {
    let date = new Date(fecha);
    return date.toLocaleDateString(culture, {
        day: "numeric",
        month: "2-digit",
        year: "2-digit",
    });
}

export function GetISODate(fecha: Date): string {
    return `20${fecha.toISOString().substring(2, 10)}`;
}

export function GetDateFromISOString(isoDate: string): Date {
    const [year, month, day] = isoDate.split("-").map(Number);

    const date = new Date(year, month - 1, day);

    return date;
}
