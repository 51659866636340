import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import exp from "constants";
import { ReactNode, useState } from "react";
import { Modal, ToggleButton } from "react-bootstrap";

interface Props {
  expanded: boolean;
  onExpandedChanged: (expanded: boolean) => void;
  className?: string;
  children?: ReactNode;
}

const FilterPanel = (props: Props) => {
  //  const [expanded, setExpanded] = useState<boolean>(props.expanded);
  if (!props.expanded)
    return (
      <ToggleButton
        value=""
        checked={props.expanded}
        size={"sm"}
        variant={"outline-primary"}
        className={`d-flex justify-content-middle align-items-center ${props.className}`}
        onClick={() => props.onExpandedChanged(true)}
        id={""}
      >
        <FontAwesomeIcon icon={faFilter} />
      </ToggleButton>
    );

  return (
    <Modal show={true} onHide={() => props.onExpandedChanged(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Filtrar</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default FilterPanel;
